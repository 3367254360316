import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";

const MeetArtistSection = ({ title, description, button, image }) => {
  return (
    <div className="MeetArtist-container">
      <div className="MeetArtist-image">
        <img src={image} alt="MeetArtist section" />
      </div>
      <div className="MeetArtist">
        <div className="MeetArtist-content">
          <h1>{title}</h1>
          <p>{description}</p>
          <div className="button-group">
            <Link to={"/artists"} className="button-explore">
              {button}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetArtistSection;
