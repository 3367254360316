import React from "react";
import "./style.scss";
import AboutSection from "../AboutSection/AboutSection.jsx";
import { home3, home2, home1 } from "../../../assets";
import HomeSection from "../HomeSection/HomeSection.jsx";
import { BoxCard2 } from "../BoxCard/index.jsx";
import MeetArtistSection from "../MeetArtist/MeetArtist.jsx";
import { CategoryBar } from "../../UI";
import { boxes } from "../../../utils";
import FunooniNFTs from "../FunooniNFTs/FunooniNFTs.jsx";
import OurServices from "../OurService/OurService.jsx";
import AuthenticitySection from "../AuthenticitySection/AuthenticitySection.jsx";

const Main = () => {
  return (
    <section className="MainSection">
      <main>
        <HomeSection
          title="Your Gateway to Exclusive Physical and Digital Artworks"
          description="Experience the evolution of art collecting with unique, authenticated Physical and Digital Artworks created by top artists and secured on the blockchain"
          button1="EXPLORE NOW"
          button2={"ABOUT US"}
          image={home1}
        />
        <CategoryBar />
        <AboutSection
          title={`Welcome to FUNOONI 
        Where Innovation Meets Creativity`}
          description="At Funooni, we’re redefining digital art by bringing together visionary artists who blend creativity with technology. Our platform showcases stunning digital artworks and innovative NFT collections, offering a space where art, inspiration, and innovation thrive. Whether you're an art lover or creator, Funooni is where your digital art journey begins."
          image={home2}
        />
        <AuthenticitySection />
      </main>
      <FunooniNFTs />
      <OurServices />

      <div className="box-container">
        {boxes.map((box, index) => (
          <BoxCard2
            key={index}
            img={box.img}
            title={box.title}
            content={box.content}
            maxWords={19}
          />
        ))}
      </div>
      <MeetArtistSection
        title="MEET OUR ARTISTS"
        description="Where we transform valuable physical artworks into unique digital assets, secured and authenticated via blockchain. Here, you can buy and sell these exclusive digital pieces, expanding your collection and engaging with a community of art lovers."
        button="DISCOVER"
        image={home3}
      />
    </section>
  );
};

export default Main;
