import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Fashion.scss";
import {
  FashionFeaturesData,
  FashionGalleryData,
  FashionProductsData,
} from "../../utils";

import { Link } from "react-router-dom";
import { hostURL } from "../../services";
import { FashionCard, SectionTitle } from "../../components";
import {
  bag,
  tshirt,
  hoodie,
  hoodie_she,
  qr_hoodie,
  image1,
} from "../../assets";

const itemsToSail = [tshirt, hoodie, bag];

const Fashion = () => {
  const [artworks, setArtworks] = useState([]);
  const [artists, setArtists] = useState({});
  const [isMobile, setIsMobile] = useState(false);

  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const handleDetailsClick = () => setIsDetailsVisible(true);
  const handleBackClick = () => setIsDetailsVisible(false);
  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent;
      const isMobileDevice =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        );
      setIsMobile(isMobileDevice);
    };

    checkIfMobile();

    const fetchArtworks = async () => {
      try {
        const response = await axios.get(`${hostURL}fashion`);
        setArtworks(response.data);

        response.data.forEach(async (artwork) => {
          try {
            const artistResponse = await axios.get(
              `${hostURL}artist/${artwork.artist_id}`
            );
            setArtists((prevArtists) => ({
              ...prevArtists,
              [artwork.artist_id]: artistResponse.data,
            }));
          } catch (error) {
            console.error(`Artist arror ID ${artwork.artist_id}:`, error);
          }
        });
      } catch (error) {
        console.error("Artwork uploading issue:", error);
      }
    };

    fetchArtworks();
  }, []);

  return (
    <div className="app">
      <header className="header">
        <div className="header-overlay">
          <div className="header_content">
            <h1 className="start_h1">Get Started with Funooni Fashion Tool</h1>
            {!isMobile ? (
              <Link to="/fashion_tool" className="start-button">
                <p className="start-inner">START DEMO</p>
              </Link>
            ) : (
              <p className="start-button disabled-button">
                Demo is not available on mobile devices
              </p>
            )}
          </div>
        </div>
      </header>

      <section className="products">
        {FashionProductsData.map((product, index) => (
          <div key={product.id} className="product-item">
            <div
              className="prod-items"
              style={{ backgroundImage: `url(${itemsToSail[index]})` }}
              alt={product.title}
            />
            <div className="overlay_y">
              <div className="overlay_y_title overlay_y_title2">
                <div className="title_content">
                  <h3>Customize Your</h3>
                  <h3 className="h3">{product.title}</h3>
                </div>

                {index === 1 || index === 2 ? (
                  <div>Will be available soon</div>
                ) : !isMobile ? (
                  <Link to="/fashion_tool" className="create-btn">
                    Create Demo
                  </Link>
                ) : (
                  <p className="create-btn disabled-button">
                    Demo is not available on mobile devices
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </section>

      <SectionTitle title="About Funooni Fashion" />
      <section className="new_about">
        <div className="container_paragraph">
          {" "}
          <h3>Sustainable NFT Fashion – Wear the Future, Grow the Planet</h3>
          <p>
            At Funooni, we are redefining NFT fashion with sustainability at its
            heart. Our exclusive digital and physical fashion clothing is made
            from certified organic materials, including premium cotton, ensuring
            that your fashion choices contribute to a healthier planet without
            compromising on luxury.
          </p>
          <p>
            Our unique approach goes beyond fashion. With every NFT fashion
            purchase, you have the opportunity to plant a tree through our Tree
            Token Initiative. As proof of your positive environmental impact,
            you will receive a separate Tree Token. This token serves as a
            symbol of your contribution to a greener world, linking your fashion
            choice directly to a tangible action for the planet.
          </p>
          <p>
            By choosing Funooni NFT fashion, you're not only making a stylish
            statement but also actively participating in sustainability. For
            more details on how you can plant a tree and earn your Tree Token,
            visit <a href="https://Funooni.io.">Funooni.io.</a>{" "}
          </p>
        </div>
        <div className="features">
          {FashionFeaturesData.map((feature) => (
            <div key={feature.id} className="feature">
              <img src={feature.icon} alt={feature.title} />
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>
      <div className="fashion-container">
        <SectionTitle title="Customized NFT Fashion" />
        <div className="container_paragraph">
          {" "}
          <h3>Own the Art, Wear the Story</h3>
          <p>
            At Funooni, we believe art should be experienced in more ways than
            one. That’s why we offer customized NFTs that let you own a unique
            digital artwork and wear it proudly on a hoodie or T-shirt. It’s not
            just about fashion—it’s about making creativity part of your
            everyday life. With every piece, you get both the digital NFT and a
            custom wearable, turning your art into something you can see, share,
            and truly call your own. Some of these designs are limited editions
            of only 500, making them even more exclusive.
          </p>
        </div>
        <FashionCard />
      </div>

      <section className="art-gallery">
        
        {FashionGalleryData.map((art) => (
          <div
            key={art.id}
            style={{ backgroundImage: `url(${art.image})` }}
            className="gallery-item"
          ></div>
        ))}
      </section>

      <SectionTitle title="Available artworks for Printing" />
      <div className="gallery-container">
        <div className="gallery-grid">
          {artworks.map((art) => (
            <div
              key={art.id}
              className="artwork-card"
              style={{ backgroundImage: `url(${art.prev_img_url})` }}
            >
              <div className="overlay_y">
                <div className="overlay_y_title">
                  <h3>{art.name}</h3>
                </div>
                <div className="card-footer">
                  <div className="card-footer-content">
                    <div className="card_content">
                      <strong>{art.name}</strong>
                      <p>{"Salah Shaheen"}</p>
                    </div>
                    {!isMobile ? (
                      <Link className="create-btn" to="/fashion_tool">
                        Create
                      </Link>
                    ) : (
                      <p className="create-btn disabled-button">
                        Demo is not available on mobile devices
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Fashion;
