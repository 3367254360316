import React, { useEffect, useState } from "react";
import "./SelectedField.scss";
import { Link } from "react-router-dom";
import { formatName } from "../../../services/data_requests";

const SelectedField = ({ data_structure, artistList, imageArray }) => {
  const [collectionData, setCollectionData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    if (
      !isDataFetched &&
      data_structure &&
      artistList &&
      imageArray.length > 0
    ) {
      const artistMap = artistList.reduce((acc, artist) => {
        acc[artist.id] = artist.name;
        return acc;
      }, {});

      const collections = data_structure.map((item, index) => ({
        name: item.collection,
        artist: artistMap[item.artist] || "Unknown Artist",
        count: data_structure.filter((i) => i.collection === item.collection)
          .length,
        previewImage: imageArray[index] || "",
      }));

      setCollectionData(collections);
      setIsDataFetched(true); // تحديث حالة تحميل البيانات
    }
  }, [isDataFetched, data_structure, artistList, imageArray]);

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  };

  const displayedItems = showAll ? collectionData : collectionData.slice(0, 9);

  return (
    <div className="selected-field">
      <ul className="collections">
        {displayedItems.map((item, index) => (
          <Link
            key={`collection-link-${index}`}
            className="collection-link"
            to={`/artist/${formatName(item.artist)}`}
          >
            <li key={`collection-item-${index}`} className="collection-item">
              <div className="bullet-grid"></div>
              <div
                className="collection_preview"
                style={{ backgroundImage: `url(${item.previewImage})` }}
              ></div>
              <div>
                <div className="collection-name">{item.name}</div>
                <div className="collection-artist">{item.artist}</div>
                <div className="collection-counter">{item.count} items</div>
              </div>
            </li>
          </Link>
        ))}
      </ul>
      {collectionData.length > 9 && (
        <div className="clct-btn" onClick={handleToggleShowAll}>
          <p>{showAll ? "Show Less" : "Show More"}</p>
        </div>
      )}
    </div>
  );
};

export default SelectedField;
