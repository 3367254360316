import React, { useEffect, useState } from "react";
import "./FunooniNFTs.scss";
import Step from "./Step";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../services";
import { useWallet } from "../../../contexts/WalletContext";
import { logo } from "../../../assets";
import { optionalInfo, stepsData } from "../../../utils";

const FunooniNFTs = ({ data }) => {
  const [artItem, setArtItem] = useState({});
  const {
    ethAddress,
    setEthAddress,
    isConnecting,
    walletConnection,
    logoutWallet,
  } = useWallet();

  const { isAuthenticated, authenticateWeb3 } = useAuth();
  useEffect(() => {
    if (data) {
      setArtItem(data);
    }
  }, [data]);

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/auth");
  };

  const formatAddress = (address) => {
    return `${address.substring(0, 6)}....${address.substring(
      address.length - 4
    )}`;
  };

  const handleWalletConnection = async () => {
    try {
      const address = await walletConnection(authenticateWeb3);
      if (address) {
        setEthAddress(address);
      }
    } catch (error) {
      console.error("Failed to connect wallet:", error);
    }
  };
  return (
    <div className="funooni-container">
      <div className="funooni-header">
        <h2>Get Started with Funooni NFTs</h2>
        <p>
          Unlock the world of exclusive digital art with Funooni NFTs! Follow
          these simple steps to dive in and start your journey:
        </p>

        <div className="buttons">
          {!ethAddress ? (
            <button
              className="connect-wallet"
              onClick={handleWalletConnection}
              disabled={isConnecting}
            >
              {isConnecting ? "Connecting..." : "Connect Wallet"}
            </button>
          ) : (
            <div className="wallet-address">{formatAddress(ethAddress)}</div>
          )}
          {!ethAddress && (
            <>
              <span>or</span>
              <button className="login" onClick={handleLoginClick}>
                Log-in
              </button>
            </>
          )}
        </div>
      </div>

      <div className="funooni_content">
        <div className="funooni-steps">
          {stepsData.map((step, index) => (
            <Step
              key={index}
              image={step.image}
              title={step.title}
              content={step.content}
              link={step.link}
              list={step.list}
            />
          ))}
        </div>
        <div className="optional-section">
          <div className="optional-box optional">
            <h2>OPTIONAL</h2>
          </div>
          {optionalInfo.map((info, index) => (
            <div key={index} className="optional-box">
              <h4>{info.title}</h4>
              <p>{info.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FunooniNFTs;
