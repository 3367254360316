import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import "../../Common/NavBar/NavBar.scss";
import { useWallet } from "../../../contexts/WalletContext";
import { hostURL, postLogout } from "../../../services";

const Popup = styled.div.attrs({ className: "popup-mainmenu" })`
  position: fixed;
  overflow-x: hidden;
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 99;

  @media (max-width: 600px) {
    animation: slideInVertical 0.5s ease-in-out;
  }

  @media (min-width: 601px) {
    animation: slideInHorizontal 0.5s ease-in-out;
  }
`;

const Overlay = styled.div.attrs({ className: "overlay" })``;

const LogoutButton = styled.li`
  margin-top: auto;
  background-color: #ff4d4d;
  color: white;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e60000;
  }
`;

const PopupMenu = ({ marginTop, togglePopup }) => {
  const { setEthAddress, logoutWallet } = useWallet();
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => JSON.parse(localStorage.getItem("isAuthenticated")) || false
  );

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${hostURL}user`, {
          withCredentials: true,
        });
        if (response.data) {
          setIsAuthenticated(true);
          localStorage.setItem("isAuthenticated", JSON.stringify(true));
        } else {
          setIsAuthenticated(false);
          localStorage.setItem("isAuthenticated", JSON.stringify(false));
        }
      } catch (error) {
        setIsAuthenticated(false);
        localStorage.setItem("isAuthenticated", JSON.stringify(false));
      }
    };

    checkAuth();
  }, []);

  const handleLogout = async () => {
    const result = await postLogout();
    if (result.success) {
      setIsAuthenticated(false);
      localStorage.removeItem("isAuthenticated");
      logoutWallet();
      setEthAddress(null);
      window.location.href = "/";
    } else {
      alert(result.errorMessage);
    }
  };

  return (
    <>
      <Overlay className="active" onClick={togglePopup} />
      <Popup style={{ marginTop: marginTop }}>
        <ul className="popup-menu-container">
          <li className="pop_link-btn" onClick={togglePopup}>
            {isAuthenticated ? (
              <>
                <Link to="/profile">
                  <p className="pop-up-text">Profile</p>
                </Link>
                <Link onClick={handleLogout}>
                  <p className="pop-up-text logout">Logout</p>
                </Link>
              </>
            ) : (
              <Link to="/auth">
                <p className="pop-up-text">Log-in</p>
              </Link>
            )}
          </li>
          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="/">
              <p className="pop-up-text mv1">Home</p>
            </Link>
          </li>
          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="/about">
              <p className="pop-up-text mv1">About</p>
            </Link>
          </li>
          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="/media">
              <p className="pop-up-text mv1">Media</p>
            </Link>
          </li>
          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="/fashion">
              <p className="pop-up-text mv1">Fashion</p>
            </Link>
          </li>
          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="/nfts">
              <p className="pop-up-text mv1">NFTs</p>
            </Link>
          </li>
          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="https://auction.funooni.com/">
              <p className="pop-up-text mv1">Auction</p>
            </Link>
          </li>
          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="/artworks">
              <p className="pop-up-text mv1">Artworks</p>
            </Link>
          </li>
          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="/artists">
              <p className="pop-up-text mv1">Artists</p>
            </Link>
          </li>
          <li className="pop_link-btn" onClick={togglePopup}>
            <Link to="/legal">
              <p className="pop-up-text mv1">Legal</p>
            </Link>
          </li>
        </ul>
      </Popup>
    </>
  );
};

export { Overlay };
export default PopupMenu;
