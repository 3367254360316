import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../../services/data_requests";
import "./NavBar.scss";
import { Link, useLocation } from "react-router-dom";
import BurgerButton from "../../UI/BurgerButton/BurgerButton";
import PopupMenu, { Overlay } from "../../UI/PopupMenu/PopupMenu";
import { logo } from "../../../assets";
import { useWallet } from "../../../contexts/WalletContext";

const NavBar = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const {
    ethAddress,
    setEthAddress,
    isConnecting,
    walletConnection,
    logoutWallet,
  } = useWallet();
  const { isAuthenticated, authenticateWeb3 } = useAuth();
  const handleNavBarRef = useRef(null);
  const [marginTop, setMarginTop] = useState(0);

  const location = useLocation();
  const pagesWithCustomHeight = ["/auth", "/pasword_reset"];
  const isCustomHeightPage = pagesWithCustomHeight.includes(location.pathname);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    setIsScrolled(scrollY > 200);
  };

  useEffect(() => {
    if (handleNavBarRef.current) {
      setMarginTop(handleNavBarRef.current.clientHeight);
    }
  }, [isPopupOpen]);

  const handleWalletConnection = async () => {
    try {
      const address = await walletConnection(authenticateWeb3);
      if (address) {
        setEthAddress(address);
      }
    } catch (error) {
      console.error("Failed to connect wallet:", error);
    }
  };
  const formatAddress = (address) => {
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 4
    )}`;
  };

  return (
    <div
      className={`NavBar ${
        isCustomHeightPage ? "custom-height" : "custom-height2"
      }`}
    >
      <div
        className={`nav-bar-buf ${isScrolled ? "scrolled" : ""}`}
        ref={handleNavBarRef}
      >
        <div className="nav-container">
          <div className="nav-upper">
            <Link to="/">
              <div className="inter_active_icon">
                <div
                  className="nav-logo"
                  style={{
                    background: `url(${logo})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    transform: "scale(2)",
                  }}
                ></div>
                <div className="nav-seporator_icon"></div>
                <div className="nav-company_title">funooni</div>
              </div>
            </Link>
            <div className="burger-menu">
              <div className="connect-metamask-div">
                {!ethAddress ? (
                  <button
                    className="connect-metamask-btn"
                    onClick={handleWalletConnection}
                    disabled={isConnecting}
                  >
                    {isConnecting ? "Connecting..." : "Connect MetaMask"}
                  </button>
                ) : (
                  <div className="wallet-address">
                    {formatAddress(ethAddress)}
                  </div>
                )}
              </div>
              <BurgerButton onClick={() => setPopupOpen(!isPopupOpen)} />
            </div>
          </div>
        </div>
        <div className="nav-stroke">
          <ul className="nav-link-container">
            <li className="nav-point">
              <Link to="/" className="nv-link type2">
                <p>Home</p>
              </Link>
            </li>
            <li className="nav-point">
              <Link to="/about" className="nv-link type2">
                <p>About Us</p>
              </Link>
            </li>
            <li className="nav-point">
              <Link to="/nfts" className="nv-link type2">
                <p>NFTs</p>
              </Link>
            </li>
            <li className="nav-point">
              <Link to="https://auction.funooni.com/" className="nv-link type2">
                <p>Auction</p>
              </Link>
            </li>
            <li className="nav-point">
              <Link to="/artworks" className="nv-link type2">
                <p>Artworks</p>
              </Link>
            </li>
            <li className="nav-point">
              <Link to="/artists" className="nv-link type2">
                <p>Artists</p>
              </Link>
            </li>
            <li className="nav-point">
              <Link to="/media" className="nv-link type2">
                <p>Media</p>
              </Link>
            </li>
            <li className="nav-point">
              <Link to="/fashion" className="nv-link type2">
                <p>Fashion</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {isPopupOpen && (
        <>
          <Overlay className="active" onClick={() => setPopupOpen(false)} />
          <PopupMenu
            marginTop={marginTop}
            togglePopup={() => setPopupOpen(false)}
          />
        </>
      )}
    </div>
  );
};

export default NavBar;
