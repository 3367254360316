import { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import "./HeroSection.css";
import { imgg1, imgg12, imgg21, imgg28, imgg7 } from "../../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const images = [imgg1, imgg7, imgg12, imgg21, imgg28];

export default function HeroSection() {
  const [isPlaying, setIsPlaying] = useState(true);
  const swiperRef = useRef(null);

  const togglePlay = () => {
    setIsPlaying((prev) => !prev);
  };

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (isPlaying) {
        swiperRef.current.swiper.autoplay.start();
      } else {
        swiperRef.current.swiper.autoplay.stop();
      }
    }
  }, [isPlaying]);

  return (
    <div className="portfolio-container">
      <Swiper
        navigation={{
          nextEl: ".swiper-button-next-custom",
          prevEl: ".swiper-button-prev-custom",
        }}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        modules={[Navigation, Autoplay]}
        className="swiper-container"
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {images.map((src, index) => (
          <SwiperSlide key={index}>
            <div className="image-wrapper">
              <img src={src} alt="Portfolio" className="portfolio-image" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="overlay"></div>
      <div className="portfolio-text">
        <div className="portfolio-text2">The Gaza </div>
        Photo Diaries
      </div>

      <div className="swiper-nav-buttons">
        <div className="swiper-button-next-custom">
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <div className="swiper-button-prev-custom">
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
    </div>
  );
}
