import React, { useState } from "react";
import { Link } from "react-router-dom";
import { hoodies, tShirts } from "../../utils";

const FashionCard = () => {
  const [selectedCategory, setSelectedCategory] = useState("hoodie");
  const [visibleDetails, setVisibleDetails] = useState({});

  const handleDetailsClick = (id) => {
    setVisibleDetails((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const displayedItems = selectedCategory === "hoodie" ? hoodies : tShirts;

  return (
    <div className="container-fashion-category">
      <div className="fashion-category-buttons">
        <button
          className={`category-btn ${
            selectedCategory === "hoodie" ? "active" : ""
          }`}
          onClick={() => setSelectedCategory("hoodie")}
        >
          Hoodie
        </button>
        <button
          className={`category-btn ${
            selectedCategory === "tshirt" ? "active" : ""
          }`}
          onClick={() => setSelectedCategory("tshirt")}
        >
          T-Shirt
        </button>
      </div>
      <div className="fashion-card-container">
        {displayedItems.map((item) => (
          <div key={item.id} className="fashion-card">
            {!visibleDetails[item.id] ? (
              <div className="fashion-card-right">
                <div
                  className="hoodie-image"
                  style={{ backgroundImage: `url(${item.image})` }}
                ></div>
                <div className="fashion-actions">
                  <Link to={item.purchaseLink} className="fashion-btn white">
                    Purchase
                  </Link>
                  <button
                    className="fashion-btn black"
                    onClick={() => handleDetailsClick(item.id)}
                  >
                    Details
                  </button>
                </div>
              </div>
            ) : (
              <div className={`fashion-card-left show`}>
                <div className="left">
                  <div className="container-qr-code">
                    <div
                      className="qr-code"
                      style={{ backgroundImage: `url(${item.artwork})` }}
                    ></div>
                  </div>
                  <div className="fashion-details">
                    <div className="fashion-item">
                      <span>Artwork name:</span>
                      <span>{item.name}</span>
                    </div>
                    <div className="fashion-item">
                      <span>Artist:</span>
                      <span>{item.artist}</span>
                    </div>
                    <div className="fashion-item">
                      <span>Collection:</span>
                      <span>{item.collection}</span>
                    </div>
                  </div>
                  <div className="fashion-actions-2">
                    <button
                      className="fashion-btn"
                      onClick={() => handleDetailsClick(item.id)}
                    >
                      Back
                    </button>
                    <Link to={item.detailsLink} className="question-mark">
                      ?
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FashionCard;
