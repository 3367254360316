import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Alchemy, Network } from "alchemy-sdk";
import "./Letter.scss";
import { hostURL, toTitleCase } from "../../services";
import { logo } from "../../assets";
import html2pdf from "html2pdf.js";

const alchemy = new Alchemy({
  apiKey: "m41T5ThjocusPU5g2OthxFelIYDnECJc",
  network: Network.ETH_MAINNET,
});

const Letter = () => {
  const { contractAddress, tokenId, artist_id } = useParams();
  const [nftData, setNftData] = useState(null);
  const [artistData, setArtistData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shareText, setShareText] = useState("Share");
  const certificateRef = useRef(null);

  useEffect(() => {
    const fetchNFTData = async () => {
      try {
        const response = await alchemy.nft.getNftMetadata(
          contractAddress,
          tokenId
        );
        console.log("Alchemy Reply:", response);
        setNftData(response);
      } catch (error) {
        console.error("Getting NFT data error:", error);
      }
    };

    const fetchArtistData = async () => {
      try {
        let allArtists = [];
        let offset = 0;
        const count = +10;
        let hasMoreData = true;

        while (hasMoreData) {
          const response = await fetch(
            `${hostURL}artist?offset=${offset}&count=${count}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          allArtists = allArtists.concat(data);

          if (data.length < count || data.length === 0) {
            hasMoreData = false;
          } else {
            offset += count;
          }
        }

        const artist = allArtists.find(
          (artist) => artist.id === parseInt(artist_id)
        );
        setArtistData(artist || null);
      } catch (error) {
        console.error("Error fetching artist data:", error);
      }
    };

    const fetchData = async () => {
      await Promise.all([fetchNFTData(), fetchArtistData()]);
      setLoading(false);
    };

    if (contractAddress && tokenId && artist_id) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [contractAddress, tokenId, artist_id]);

  if (loading) return <p>Downloading...</p>;
  if (!nftData)
    return (
      <p>
        NFT Data missed. Contract: {contractAddress || "missed"}, Token:{" "}
        {tokenId || "missed"}
      </p>
    );

  const metadata = nftData.raw?.metadata || {};
  const attributes = metadata.attributes || [];

  const artist =
    attributes.find((attr) => attr.trait_type === "Artist")?.value ||
    artistData?.name ||
    "Unknown Artist";
  const rarity =
    attributes.find((attr) => attr.trait_type === "Rarity")?.value ||
    "Not specified";
  const category =
    attributes.find((attr) => attr.trait_type === "Category")?.value ||
    "Digital Art";
  const collection =
    attributes.find((attr) => attr.trait_type === "Collection")?.value ||
    "Unknown Collection";
  const signedBy = artistData?.signature_url || "";

  const name = nftData.name || metadata.name || "No name";
  const description = nftData.description || "No description available";
  const imageUrl = nftData.image?.cachedUrl || "/placeholder.jpg";
  const tokenType = nftData.tokenType || "Unknown";
  const mintDate = nftData.mint?.timestamp
    ? new Date(nftData.mint.timestamp).toLocaleString()
    : "Unknown";

  const handleShare = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setShareText("Copied");
        setTimeout(() => setShareText("Share"), 3000);
      })
      .catch((err) => console.error("Failed to copy the link: ", err));
  };

  const handleDownloadPDF = () => {
    const certificateElement = certificateRef.current;
    if (!certificateElement) return;

    const options = {
      margin: 0,
      filename: "Certificate_of_Authenticity.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 3, useCORS: true },
      jsPDF: { format: "a4", orientation: "portrait" },
    };

    const downloadButton = document.querySelector(".hide-in-pdf");
    if (downloadButton) downloadButton.style.display = "none";

    html2pdf()
      .set(options)
      .from(certificateElement)
      .save()
      .then(() => {
        if (downloadButton) downloadButton.style.display = "block";
      });
  };

  const hasDetails =
    contractAddress || tokenId || (tokenType && tokenType !== "Unknown");

  const hasHistory = mintDate && mintDate !== "Unknown";

  return (
    <div className="letter-container">
      <div className="letter-content-wrapper">
        <div className="header-container">
          <div className="letter-image-section">
            {imageUrl && imageUrl !== "/placeholder.jpg" && (
              <div className="img">
                <div className="letter-preview-container">
                  <div
                    className="letter-preview"
                    style={{
                      backgroundImage: `url(${imageUrl})`,
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>

          <div className="header-section">
            <div className="letter-content">
              <div className="letter-header">
                <div className="letter-ars-title">
                  {name && name !== "No name" && (
                    <h1 className="letter-title">{name}</h1>
                  )}
                  <div className="share-btn" onClick={handleShare}>
                    {shareText}
                  </div>
                </div>

                {artist && artist !== "Unknown Artist" && (
                  <div className="letter-title-under">
                    <div className="letter-author">
                      Artist: {toTitleCase(artist)}
                    </div>
                  </div>
                )}
              </div>
              <div className="sub-letter-content">
                {description && description !== "No description available" && (
                  <>
                    <p className="Description">Description</p>
                    <p className="letter-description">{description}</p>
                  </>
                )}
                {category && category !== "Digital Art" && (
                  <button className="letter-Digital">{category}</button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="letter-info-section">
          <div className="letter-certificate-section" ref={certificateRef}>
            <div className="letter-certificate-header">
              <div className="l-container">
                {logo && (
                  <img
                    src={logo}
                    alt="Funooni Logo"
                    className="letter-logo"
                    onError={(e) => {
                      e.target.src = logo;
                    }}
                  />
                )}
                <div>
                  <h2 className="letter-certificate-title">Funooni Platform</h2>
                  <h3 className="letter-certificate-subtitle">
                    Certificate of Authenticity
                  </h3>
                </div>
              </div>
              <button
                className="letter-upload hide-in-pdf"
                onClick={handleDownloadPDF}
              >
                Download
              </button>
            </div>
            <div className="letter-certificate-content">
              {imageUrl && imageUrl !== "/placeholder.jpg" && (
                <div className="div-letter-certificate-image">
                  <img
                    src={imageUrl}
                    alt="Certificate Image"
                    className="letter-certificate-image"
                    onError={(e) => {
                      e.target.src = "/placeholder.jpg";
                    }}
                  />
                </div>
              )}
              <div className="letter-certificate-details">
                <div className="first-letter-certificate-details">
                  {name && name !== "No name" && (
                    <div className="letter-p">
                      <strong>Title</strong>{" "}
                      <p className="letter-p-content">{name}</p>
                    </div>
                  )}
                  {artist && artist !== "Unknown Artist" && (
                    <div className="letter-p">
                      <strong>Created by</strong>{" "}
                      <p className="letter-p-content">{artist}</p>
                    </div>
                  )}
                  {category && category !== "Digital Art" && (
                    <div className="letter-p">
                      <strong>Medium</strong>{" "}
                      <p className="letter-p-content">{category}</p>
                    </div>
                  )}
                  {rarity && rarity !== "Not specified" && (
                    <div className="letter-p">
                      <strong>Rarity</strong>{" "}
                      <p className="letter-p-content">{rarity}</p>
                    </div>
                  )}
                  {collection && collection !== "Unknown Collection" && (
                    <div className="letter-p">
                      <strong>Collection</strong>{" "}
                      <p className="letter-p-content">{collection}</p>
                    </div>
                  )}
                </div>
                {signedBy && (
                  <div className="letter-p">
                    <strong>Signed by</strong>{" "}
                    <img
                      className="Signed-img"
                      src={signedBy}
                      alt="Artist Signature"
                      onError={(e) => (e.target.style.display = "none")}
                    />
                  </div>
                )}
                <div className="verified">
                  <div className="second-verified">
                    <i
                      className="fa-solid fa-check-circle"
                      style={{
                        color: "gray",
                        fontSize: "24px",
                        marginRight: "10px",
                      }}
                    ></i>
                    <span>Verified issuer</span>
                  </div>
                  <div className="first-verified">
                    <i
                      className="fa-solid fa-check-circle"
                      style={{
                        color: "gray",
                        fontSize: "24px",
                        marginRight: "10px",
                      }}
                    ></i>
                    <span>Creator authorized</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {hasDetails && (
            <div className="letter-details-section">
              <h2 className="letter-subtitle">Details</h2>
              {contractAddress && (
                <p className="letter-p letter-p2">
                  <strong>Contract Address</strong>{" "}
                  <span>{contractAddress}</span>
                </p>
              )}
              {tokenId && (
                <p className="letter-p">
                  <strong>Token ID</strong> <span>{tokenId}</span>
                </p>
              )}
              {tokenType && tokenType !== "Unknown" && (
                <p className="letter-p">
                  <strong>Token Standard</strong> <span>{tokenType}</span>
                </p>
              )}
            </div>
          )}

          {hasHistory && (
            <div className="letter-history-section">
              <h2 className="letter-subtitle">History</h2>
              {mintDate && mintDate !== "Unknown" && (
                <p className="letter-p">
                  <strong>Minted</strong> <span>{mintDate}</span>
                </p>
              )}
              <div className="history">Full transaction history</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Letter;
