import React from 'react';
import "./RequestCard.scss";
import { hostURL } from "../../../services";

const RequestCard = ({ data, onDelete, ...attr }) => {
  const { id, type, img_url, content } = data || {};
  
  const contentItem = content && content[0] ? content[0] : {};
  const { artistName } = contentItem;
  
  const {
    artworkName,
    collection,
    rarity,
    medium,
    categories,
    description,
    price,
    currency
  } = type !== "Edit Artist Profile" ? contentItem : {};
  
  const {
    email,
    trajectories,
    bio
  } = type === "Edit Artist Profile" ? contentItem : {};

  const handleDelete = async () => {
    try {
      const response = await fetch(`${hostURL}LOA/creator_requests/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        console.log(`Successfully deleted request ${id}`);
        if (onDelete) {
          onDelete(id); 
        }
      } else {
        console.error("Failed to delete request:", response.status);
        alert("Failed to delete the request");
      }
    } catch (error) {
      console.error("Error deleting request:", error);
      alert("Error occurred while deleting the request");
    }
  };

  return (
    <div className="request-card" {...attr}>
      <div className="request-image">
        {img_url && (
          <img 
            src={img_url} 
            alt={type === "Edit Artist Profile" ? (artistName || 'Artist Profile') : (artworkName || 'Artwork')} 
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = 'path/to/fallback-image.jpg';
            }}
          />
        )}
      </div>
      <div className="request-body">
        <div className="request-text">
          {type === "Edit Artist Profile" ? (
            <>
              <div className="art-name">{artistName || 'Unnamed Artist'}</div>
              <div className="request-details">
                <div className="detail-item">
                  <span className="label">Type:</span> {type}
                </div>
                <div className="detail-item">
                  <span className="label">Email:</span> {email || 'N/A'}
                </div>
                <div className="detail-item">
                  <span className="label">Trajectories:</span> 
                  {trajectories?.length > 0 ? trajectories.join(', ') : 'None'}
                </div>
              </div>
              <div className="description">
                <span className="label">Biography:</span>
                <p>{bio || 'No biography available'}</p>
              </div>
            </>
          ) : (
            <>
              <div className="art-name">{artworkName || 'Untitled'}</div>
              <div className="artist-name">by {artistName || 'Unknown Artist'}</div>
              <div className="request-details">
                <div className="detail-item">
                  <span className="label">Type:</span> {type}
                </div>
                <div className="detail-item">
                  <span className="label">Collection:</span> {collection || 'N/A'}
                </div>
                <div className="detail-item">
                  <span className="label">Rarity:</span> {rarity || 'N/A'}
                </div>
                <div className="detail-item">
                  <span className="label">Medium:</span> {medium || 'N/A'}
                </div>
                <div className="detail-item">
                  <span className="label">Categories:</span> 
                  {categories?.length > 0 ? categories.join(', ') : 'None'}
                </div>
                <div className="detail-item price">
                  <span className="label">Price:</span> 
                  {price ? `${price} ${currency || ''}` : 'Not specified'}
                </div>
              </div>
              <div className="description">
                <span className="label">Description:</span>
                <p>{description || 'No description available'}</p>
              </div>
            </>
          )}
        </div>
        <div className="requests-action">
          <div 
            className="request-delete" 
            onClick={handleDelete}
          >
            Delete
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestCard;