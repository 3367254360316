import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import "./ArtworksCarousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { formatName, hostURL, toTitleCase } from "../../../services";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";

const ArtworksCarousel = ({ title, medium, categories }) => {
  const [artworks, setArtworks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(1);
  const [showArrows, setShowArrows] = useState(true);

  const slidesToScroll = 1;

  const sliderRef = React.useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: slidesToScroll,
    lazyLoad: "ondemand",
    nextArrow: showArrows ? <SampleNextArrow /> : null,
    prevArrow: showArrows ? <SamplePrevArrow /> : null,
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: slidesToScroll,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: slidesToScroll,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: slidesToScroll,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: slidesToScroll,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchArtworks = async () => {
      const cacheKey = `artworks-${medium}-${categories}`;
      const cachedData = sessionStorage.getItem(cacheKey);

      if (cachedData) {
        setArtworks(JSON.parse(cachedData));
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await axios.get(`${hostURL}artwork`, {
          params: { medium, categories, offset: 0, count: 10 },
        });

        const artworksData = response.data.map((item) => ({
          id: item.id,
          name:
            typeof item.name === "object"
              ? JSON.stringify(item.name)
              : item.name,
          artist: item.artist?.name || item.artist,
          image: item.prev_img_url || "https://via.placeholder.com/150",
        }));

        sessionStorage.setItem(cacheKey, JSON.stringify(artworksData));
        setArtworks(artworksData);
      } catch (error) {
        console.error("Error fetching artworks:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArtworks();
  }, [medium, categories]);

  useEffect(() => {
    const calculateTotalSections = debounce(() => {
      const windowWidth = window.innerWidth;
      let slidesToShow = 4;

      if (windowWidth <= 1200 && windowWidth > 992) slidesToShow = 4;
      else if (windowWidth <= 992 && windowWidth > 768) slidesToShow = 3;
      else if (windowWidth <= 768 && windowWidth > 576) slidesToShow = 2;
      else if (windowWidth <= 576) slidesToShow = 1;

      const totalSections = Math.ceil(
        (artworks.length - slidesToShow) / slidesToScroll + 1
      );
      setTotalSlides(totalSections);
      setShowArrows(totalSections > 1);
    }, 300);

    calculateTotalSections();
    window.addEventListener("resize", calculateTotalSections);

    return () => {
      window.removeEventListener("resize", calculateTotalSections);
    };
  }, [artworks, slidesToScroll]);

  const handleScrollSectionClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <div className="artworks-carousel">
      <div className="carousel-header">
        <div>
          <h2>{title}</h2>
          <span> Artworks</span>
        </div>
        <button className="view-all-btn">
          View all {medium || categories}
        </button>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <Slider ref={sliderRef} {...settings}>
          {artworks.map((artwork) => (
            <div key={artwork.id} className="artwork-card">
              <img src={artwork.image} alt={artwork.name} />
              <div className="artwork-info">
                <div className="art-info-2">
                  <div>
                    <h3>{toTitleCase(artwork.name)}</h3>
                    <p>{toTitleCase(artwork.artist)}</p>
                  </div>
                  <Link
                    to={`/artwork/${formatName(artwork.name)}-${artwork.id}`}
                    className="learn-btn"
                  >
                    LEARN
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}

      <div className="scroll-line">
        {totalSlides <= 1 ? (
          <div className="scroll-section full"></div>
        ) : (
          Array.from({ length: totalSlides }, (_, index) => (
            <div
              key={index}
              className={`scroll-section ${
                index === Math.floor(currentSlide / slidesToScroll)
                  ? "active"
                  : ""
              }`}
              onClick={() => handleScrollSectionClick(index)}
              style={{
                flexBasis: `${100 / totalSlides}%`,
              }}
            ></div>
          ))
        )}
      </div>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow next-arrow" onClick={onClick}>
      <span>&gt;</span>
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow prev-arrow" onClick={onClick}>
      <span>&lt;</span>
    </div>
  );
};

export default ArtworksCarousel;
