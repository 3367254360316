import React, { useState } from "react";
import "./BoxCard2.scss";
import { IoIosArrowDown } from "react-icons/io";

const BoxCard2 = ({ img, title, content, maxWords }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleContent = () => setIsExpanded(!isExpanded);
  const imgStyle = {
    background: `url(${img})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  const truncatedContent =
    content.split(" ").slice(0, maxWords).join(" ") +
    (content.split(" ").length > maxWords ? "..." : "");

  return (
    <div
      className={`box-card ${isExpanded ? "expanded" : ""}`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      {!isExpanded && <div className="a-img-item" style={imgStyle} />}

      <h3 className="box-title">{title}</h3>
      <p className="box-content">{isExpanded ? content : truncatedContent}</p>
      <button className="box-toggle" onClick={toggleContent}>
        <IoIosArrowDown
          className={`arrow-icon ${isExpanded ? "rotated" : ""}`}
        />
      </button>
    </div>
  );
};

export default BoxCard2;
