import React, { useState, useRef } from "react";
import "./CertificateTool.scss";
import { FaPen } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ArtistProfile = () => {
  const [trajectoriesInput, setTrajectoriesInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const API_BASE_URL = "https://api.funooni.com";
  const [editingField, setEditingField] = useState(null);
  const inputRef = useRef(null);
  const [showTrajectoryInput, setShowTrajectoryInput] = useState(false);

  const [formData, setFormData] = useState({
    type: "Edit Artist Profile",
    artistName: "",
    email: "",
    trajectories: ["Digital Art"],
    bio: "",
    image: null,
  });

  const handleAddTrajectories = () => {
    if (
      trajectoriesInput &&
      !formData.trajectories.includes(trajectoriesInput)
    ) {
      setFormData({
        ...formData,
        trajectories: [...formData.trajectories, trajectoriesInput],
      });
      setTrajectoriesInput("");
      setShowTrajectoryInput(false);
    }
  };

  const handleRemoveTrajectory = (trajectoryToRemove) => {
    setFormData({
      ...formData,
      trajectories: formData.trajectories.filter(
        (trajectory) => trajectory !== trajectoryToRemove
      ),
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    if (file) {
      if (file.size > 32 * 1024 * 1024) {
        toast.error("File size exceeds 32 MB limit.");
        return;
      }
      setFormData({
        ...formData,
        [name]: file,
      });
    }
  };

  const validateForm = () => {
    const requiredFields = ["artistName", "email", "bio"];
    for (const field of requiredFields) {
      if (!formData[field]) {
        toast.error(`Field "${field}" is required.`);
        return false;
      }
    }
    if (formData.trajectories.length === 0) {
      toast.error("At least one trajectory is required.");
      return false;
    }
    if (!formData.image) {
      toast.error("Profile image is required.");
      return false;
    }
    return true;
  };

  const checkCreatorStatus = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/LOA/is_creator`, {
        method: "GET",
        credentials: "include",
      });
      if (!response.ok) throw new Error("Failed to verify creator status");
      const data = await response.json();
      return data.status === true;
    } catch (err) {
      toast.error("Error verifying creator status: " + err.message);
      return false;
    }
  };

  const createTextRecord = async () => {
    const requestBody = {
      type: formData.type,
      content: [
        {
          artistName: formData.artistName,
          email: formData.email,
          trajectories: formData.trajectories,
          bio: formData.bio,
        },
      ],
    };

    try {
      const response = await fetch(`${API_BASE_URL}/LOA/request`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Create Text Record Response:", data);
      if (!data.loa_id) {
        throw new Error("No loa_id returned from /LOA/request");
      }
      return data.loa_id;
    } catch (err) {
      toast.error("Error creating text record: " + err.message);
      return null;
    }
  };

  const uploadImage = async (file, recordId) => {
    const formDataToSend = new FormData();
    formDataToSend.append("file", file);
    formDataToSend.append("recordId", recordId);

    try {
      const response = await fetch(
        `${API_BASE_URL}/LOA/upload_file?id=${recordId}`,
        {
          method: "POST",
          credentials: "include",
          body: formDataToSend,
        }
      );
      if (!response.ok) throw new Error("Failed to upload image");
      const result = await response.json();
      return result;
    } catch (err) {
      toast.error("Error uploading image: " + err.message);
      return null;
    }
  };

  const handleVerify = async () => {
    setIsLoading(true);
    setError(null);
    setSuccessMessage(null);

    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    const isCreator = await checkCreatorStatus();
    if (!isCreator) {
      toast.error("You are not authorized as a creator.");
      setIsLoading(false);
      return;
    }

    const recordId = await createTextRecord();
    if (!recordId) {
      setIsLoading(false);
      return;
    }

    const imageUploadResult = await uploadImage(formData.image, recordId);
    if (!imageUploadResult) {
      setIsLoading(false);
      return;
    }

    // const jsonData = JSON.stringify(formData, null, 2);
    // const blob = new Blob([jsonData], { type: "application/json" });
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(blob);
    // link.download = "artist_profile.json";
    // link.click();

    toast.success("Request to verify was sent successfully!");
    setIsLoading(false);
  };

  const handleEditClick = (fieldName) => {
    setEditingField(fieldName);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleBlur = () => {
    setEditingField(null);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.target.name === "trajectoriesInput") {
        handleAddTrajectories();
      }
      setEditingField(null);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="artist-profile">
        <div className="upload-section">
          <div className="upload-box">
            {formData.image ? (
              <img
                src={URL.createObjectURL(formData.image)}
                alt="Uploaded profile"
                className="uploaded-image"
              />
            ) : (
              <>
                Artist Profile Image
                <br />
                <span>File size should be no more than 32 MB</span>
              </>
            )}
            <input
              type="file"
              accept="image/*"
              name="image"
              className="upload-input"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className="form-section">
          <div className="type-selection">
            <button className="selected">Edit Artist Profile</button>
          </div>

          <div className="label-group">
            <label className="form-label">Artist Name</label>
            <span className="editable-wrapper">
              {editingField === "artistName" ? (
                <input
                  type="text"
                  ref={inputRef}
                  name="artistName"
                  value={formData.artistName}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                  className="editable-input"
                />
              ) : (
                <span
                  className="editable-text"
                  onClick={() => handleEditClick("artistName")}
                >
                  {formData.artistName || "Artist Name"}{" "}
                  <span className="edit-icon">
                    <FaPen />
                  </span>
                </span>
              )}
            </span>
          </div>

          <div className="label-group">
            <label className="form-label">Business e-mail</label>
            <span className="editable-wrapper">
              {editingField === "email" ? (
                <input
                  type="email"
                  ref={inputRef}
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                  className="editable-input"
                />
              ) : (
                <span
                  className="editable-text"
                  onClick={() => handleEditClick("email")}
                >
                  {formData.email || "Business e-mail"}{" "}
                  <span className="edit-icon">
                    <FaPen />
                  </span>
                </span>
              )}
            </span>
          </div>

          <div className="label-group">
            <label className="form-label">Trajectories</label>
            <div className="trajectories-list">
              {formData.trajectories.map((trajectory) => (
                <div key={trajectory} className="trajectory-item">
                  {trajectory}
                  <button
                    className="remove-trajectory"
                    onClick={() => handleRemoveTrajectory(trajectory)}
                  >
                    ✕
                  </button>
                </div>
              ))}
              <div className="trajectory-input">
                {showTrajectoryInput ? (
                  <input
                    type="text"
                    name="trajectoriesInput"
                    value={trajectoriesInput}
                    onChange={(e) => setTrajectoriesInput(e.target.value)}
                    onKeyPress={handleKeyPress}
                    onBlur={() => {
                      handleAddTrajectories();
                      setShowTrajectoryInput(false);
                    }}
                    placeholder="Add trajectory"
                    autoFocus
                  />
                ) : (
                  <button
                    className="add-trajectory-btn"
                    onClick={() => setShowTrajectoryInput(true)}
                  >
                    ➕
                  </button>
                )}
              </div>
            </div>
          </div>

          <label className="form-label">Biography</label>
          <textarea
            name="bio"
            className="textarea"
            value={formData.bio}
            onChange={handleInputChange}
            required
          ></textarea>

          <button
            className="verify-button"
            onClick={handleVerify}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Verify"}
          </button>
        </div>
      </div>
    </>
  );
};

export default ArtistProfile;
