import React, { useEffect, useState } from "react";
import "./Article.scss";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { hostURL, useToGetUserData } from "../../services";
import { BoxCard1 } from "../../components";

const Article = ({ data }) => {
  const [mediaData, setMediaData] = useState([]);
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const userInfo = useToGetUserData();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticleDetails = async () => {
      try {
        const response = await axios.get(`${hostURL}media/${id}`);
        setArticle(response.data);
      } catch (error) {
        console.error("Error fetching article details:", error);
      }
    };

    fetchArticleDetails();
  }, [id]);

  useEffect(() => {
    const fetchMediaData = async () => {
      try {
        const response = await axios.get(`${hostURL}media`);
        const sortedData = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setMediaData(sortedData.slice(0, 4));
      } catch (error) {
        console.error("Error fetching media data:", error);
      }
    };

    fetchMediaData();
  }, []);

  const handleNotificationLinkClick = (e) => {
    e.preventDefault();
    if (userInfo && userInfo.username) {
      navigate("/profile");
    } else {
      navigate("/auth");
    }
  };

  return (
    <div className="article">
      <div className="container">
        {article ? (
          <>
            <div className="image-section">
              <img
                src={article.img_url}
                alt="Art Illustration"
                className="art-image"
              />
            </div>
            <div className="content-section">
              <div className="sub_content">
                <h2>{article.title}</h2>
                <h3>{article.sub_title}</h3>
                <p className="paragraph">{article.short_description}</p>
                <div className="content_two">
                  <p className="date">{article.created_at}</p>
                  <p>
                    {article.author} {article.type}
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <p>Loading article details...</p>
        )}
      </div>

      <div className="second_section">
        {article && article.content ? (
          <>
            <div className="second_section_content">
              {article.content.map((item, index) => (
                <div key={index} className="content-item">
                  <h3>{item.title_content}</h3>
                  <p>{item.text_content}</p>
                  {item.image_content && (
                    <div className="image-section">
                      <img
                        src={item.image_content}
                        alt={item.title_content}
                        className="art-image2"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <p>Loading article details...</p>
        )}
      </div>

      <div className="notification-container">
        <p className="notification-text">
          Latest Headlines.{" "}
          <a
            href=""
            className="notification-link"
            onClick={handleNotificationLinkClick}
            target="_blank"
            rel="noopener noreferrer"
          >
            Would you like to get notifications about our updates?
          </a>
        </p>
      </div>
      <div className="box-container">
        {mediaData.map((box, index) => (
          <BoxCard1
            id={box.id}
            key={index}
            image={box.img_url}
            type={box.type}
            title={box.title}
            content={box.short_description}
            author={box.author}
            date={box.created_at}
            maxWords={20}
          />
        ))}
      </div>
    </div>
  );
};

export default Article;
