import React from "react";
import "./CategoryBar.scss";

const categories = [
  "Digital",
  "NFT",
  "Sculptures",
  "Painting",
  "Blockchain",
  "Web3",
  "Heritage",
  "Digital",
  "NFT",
  "Sculptures",
  "Painting",
  "Blockchain",
  "Web3",
  "Heritage",
];

const CategoryBar = () => {
  return (
    <div className="category-bar-container">
      <div className="category-bar">
        {[...categories, ...categories].map((category, index) => (
          <React.Fragment key={index}>
            <span className="category-item">{category}</span>
            <span className="separator">O</span>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CategoryBar;
