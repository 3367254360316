import "swiper/css";
import "swiper/css/navigation";

import {
  NewsSection,
  HeroSection,
  AboutSection,
  Photo,
} from "../../components/Galary";

export default function Gallery() {
  return (
    <div className="portfolio-containerr">
      <HeroSection />
      <AboutSection />
      <Photo />
      <NewsSection />
    </div>
  );
}
