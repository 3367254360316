import React from "react";
import styled, { keyframes } from "styled-components";
import { imgg23 } from "../../../assets";

const placeholderImage = imgg23;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SectionOne = styled.div`
  display: flex;
  height: 88vh;
  background: linear-gradient(135deg, #1a1a1a, #000);
  color: #fff;
  font-family: "Arial", sans-serif;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    min-height: 95vh;
    gap: 20px;
  }
`;

const LeftSide = styled.div`
  flex: 1;
  position: relative;
  background: url(${placeholderImage}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: clamp(10px, 2vw, 20px);

  @media (max-width: 768px) {
    flex: none;
    height: 50vh;
    padding: 15px;
  }

  @media (max-width: 480px) {
    height: 40vh;
  }
`;

const SideNav = styled.div`
  position: absolute;
  bottom: clamp(20px, 5vh, 40px);
  left: -40px;
  transform: rotate(-90deg);
  font-size: clamp(0.7rem, 1.5vw, 0.9rem);
  color: #ccc;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    position: relative;
    transform: none;
    left: 0;
    bottom: 0;
    text-align: center;
    padding: 10px 0;
  }
`;

const RightSideOne = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: clamp(30px, 5vw, 60px);
  background: rgba(0, 0, 0, 0.8);
  position: relative;

  @media (max-width: 768px) {
    flex: none;
    padding: 20px;
  }
`;

const TitleOne = styled.h1`
  font-size: clamp(1.5rem, 5vw, 2rem);
  font-weight: bold;
  margin-bottom: clamp(18px, 2vw, 20px);
  color: #f39c12;
  position: relative;
  display: inline-block;
  &::after {
    content: "";
    position: absolute;
    width: clamp(40px, 5vw, 60px);
    height: 3px;
    background: #fff;
    bottom: -10px;
    left: 0;
    transition: width 0.3s ease;
  }
  &:hover::after {
    width: 100%;
  }
`;

const DescriptionOne = styled.p`
  font-size: clamp(0.9rem, 1.5vw, 1.1rem);
  line-height: 1.7;
  color: #ddd;
  max-width: 90%;
  margin-bottom: clamp(10px, 2vw, 20px);
  animation: ${fadeIn} 1s ease forwards;

  @media (max-width: 480px) {
    line-height: 1.5;
  }
`;

const UnderlineOne = styled.div`
  width: clamp(60px, 10vw, 80px);
  height: 2px;
  background: linear-gradient(to right, #ff6200, #fff);
  margin-top: clamp(10px, 2vw, 20px);
  transition: width 0.5s ease;
  &:hover {
    width: clamp(80px, 15vw, 120px);
  }
`;

const SectionTwo = styled.div`
  display: flex;
  height: 88vh;
  background: linear-gradient(135deg, #0d0d0d, #2d2d2d);
  color: #fff;
  font-family: "Arial", sans-serif;
  position: relative;
  overflow: hidden;
  align-items: center;
  padding: 0 clamp(20px, 5vw, 50px);

  @media (max-width: 768px) {
    height: auto;
    min-height: 60vh;
    padding: 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  animation: ${slideUp} 1s ease forwards;
`;

const TitleTwo = styled.h1`
  font-size: clamp(1.2rem, 4vw, 2rem);
  font-weight: 900;
  color: #f39c12;
  text-align: center;
  margin-bottom: clamp(15px, 3vw, 30px);
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 4px;
    background: #f39c12;
    bottom: -10px;
    left: 25%;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.5s ease;
  }
  &:hover::before {
    transform: scaleX(1);
  }
`;

const DescriptionTwo = styled.p`
  font-size: clamp(0.9rem, 1.8vw, 1.2rem);
  line-height: 1.8;
  color: #e0e0e0;
  max-width: 90%;
  text-align: center;
  margin-bottom: clamp(20px, 4vw, 40px);
  padding: clamp(10px, 2vw, 20px);
  border-radius: 15px;

  @media (max-width: 480px) {
    line-height: 1.6;
  }
`;

const ElegantButton = styled.button`
  padding: clamp(8px, 1.5vw, 12px) clamp(20px, 3vw, 30px);
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  font-weight: bold;
  color: #fff;
  background: linear-gradient(45deg, #ff6200, #ff8c00);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(255, 98, 0, 0.4);
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(255, 98, 0, 0.6);
    background: linear-gradient(45deg, #ff8c00, #ff6200);
  }
`;

const UnderlineTwo = styled.div`
  width: clamp(60px, 10vw, 100px);
  height: 4px;
  background: radial-gradient(circle, #ff6200, #fff);
  margin-top: clamp(10px, 2vw, 20px);
  transition: transform 0.5s ease;
  &:hover {
    transform: scale(1.2);
  }
`;

const AboutSection = () => {
  return (
    <>
      <SectionOne>
        <LeftSide>
          <SideNav>Funooni Platform</SideNav>
        </LeftSide>
        <RightSideOne>
          <TitleOne>
            Gaza Photo Diaries: Through the lens of hope and resilience
          </TitleOne>
          <DescriptionOne>
            The Gaza Photo Diaries Project is a groundbreaking collaboration
            between Funooni, Jodar Artistry, and curated by Natali Karajeh, a
            renowned filmmaker and arts consultant. This unique initiative
            captures the resilient stories of Gaza through the eyes of talented
            photographers, sharing powerful images that embody hope and history.
            For the first time in our region, this collection of photographic
            art is offered as NFTs, with each piece accompanied by a digital
            certificate of authenticity on the blockchain, signed by the artist.
            With this project, we are creating history, preserving Gaza's spirit
            while supporting the careers of local artists. 100% of the proceeds
            go directly to these artists.
          </DescriptionOne>
          <UnderlineOne />
        </RightSideOne>
      </SectionOne>

      <SectionTwo>
        <ContentWrapper>
          <TitleTwo>A Story in Every Frame</TitleTwo>
          <DescriptionTwo>
            Step into the heart of Gaza through this powerful collection of
            photos, capturing moments of resilience, love, and everyday life
            amidst uncertainty. Each image tells a story, of laughter in the
            face of hardship, of hope shining through the ruins, and of a people
            whose spirit remains unbreakable. Explore the untold narratives and
            the strength of Gaza, one frame at a time.
          </DescriptionTwo>
        </ContentWrapper>
      </SectionTwo>
    </>
  );
};

export default AboutSection;
