export const legacyText = [
  {
    name: <p className="lgcy-title">Buyer Guarantee</p>,
    source: "buyer-guarantee",
    text: (
      <ul>
        <li>
          <p className="lgcy-sub-title">Secure Payment</p>
          <p className="lgcy-text">
            Purchases completed through our platform are powered by Stripe, the
            leader in online payment processing that’s trusted by millions of
            global businesses. And for NFT transactions all crypto payments are
            processed through audited and highly secured smart contracts.
          </p>
        </li>
        <li>
          <p className="lgcy-sub-title">Money-Back Guarantee</p>
          <p className="lgcy-text">
            Kindly note in advance that, owing to the unique nature of art,
            returns are not always guaranteed but In the event that a physical
            piece of artwork purchased via the Funooni platform is lost,
            damaged, or found to be inaccurately described, we will collaborate
            with you to identify the best solution, which may include a complete
            refund.
          </p>
        </li>
        <li>
          <p className="lgcy-sub-title">All personal detail is secure</p>
          <p className="lgcy-text">
            We don't exchange or sell any personal data.
          </p>
        </li>
        <li>
          <p className="lgcy-sub-title">Shipping & Packaging</p>
          <p className="lgcy-text">
            Funooni works with specialized fine arts logistic partner to ensure
            an artwork’s utmost protection during transit, tailored to each
            artwork and collector’s needs.
          </p>
        </li>
        <li>
          <p className="lgcy-sub-title">Certificates of Authenticity</p>
          <p className="lgcy-text">
            We Provide certificates with each sold artwork including NFTs and
            already signed pieces.
          </p>
        </li>
        <li>
          <p className="lgcy-sub-title">Cancellations</p>
          <p className="lgcy-text">
            Orders cancelled before a work is dispatched for delivery will incur
            a 5% processing fee. Orders cancelled after a work is delivered will
            incur a 10% processing fee; moreover, the delivery costs to return
            the piece will be deducted from the amount refunded to collectors.
            Refunds will only be issued once a condition report has been carried
            out and the work is deemed to be in good condition. If the work has
            incurred serious damage owing to the return trip and needs to be
            restored, this cost will also be deducted from the amount refunded
            to the collector.
          </p>
        </li>
      </ul>
    ),
  },
  {
    name: <p className="lgcy-title">Terms of use</p>,
    source: "terms-of-use",
    text: (
      <p className="lgcy-text">
        Welcome to Funooni Platform. The Terms of Use set out in this document
        ("Terms") constitute an agreement between you (“you" or "your") Funoon
        Arab FZ-LLC (Funooni Platform), incorporated under the laws of United
        Arab Emirates, with registration number 0000004036539 ("Funooni
        Platform" or "we" or "our" or "us"). The Terms govern your access to and
        use of our website and services (collectively, "Services"). By accessing
        or using our Services in any way, you agree to these Terms. If you do
        not agree to these Terms, you are not permitted to access or use our
        Services. Please read these Terms carefully, and feel free to contact us
        if you have any questions.
        <br />
        <br />
        <p className="lgcy-sub-title">1. General Terms</p>
        <br />
        <span className="term-number">1.1</span> You may use our Services only
        if you can form a binding contract with us, and only as permitted by
        law. You are not allowed to use our Services if you are a person under
        the age of 13 (thirteen), provided that you will not be able to use our
        Services for buying or selling any property unless you are over the age
        of eighteen (18).
        <br />
        <span className="term-number"> 1.2 </span>Additional eligibility
        requirements may apply to some Services, and we will notify you of those
        requirements in these Terms, via email or otherwise in connection with
        those Services.
        <br />
        <span className="term-number">1.3 </span> If you are using our Services
        on behalf of a company or other entity, you represent and warrant that
        you are at least eighteen (18) years old, and that you are authorized to
        bind that company or other entity to these Terms, in which case the
        terms "you" and "your" in these Terms will refer to that entity.
        <br />
        <span className="term-number"> 1.4 </span>You are responsible for
        maintaining the confidentiality of your account password, and for all
        activities that occur through your account. You agree to notify us
        immediately if you suspect or become aware of any unauthorized access to
        or use of your account or other breach of security on our Services.
        <br />
        <span className="term-number">1.5</span> The permission we give you to
        register for a Funooni Online account and use our Services under these
        Terms is non-exclusive and non-transferable. You are not allowed to
        rent, sell, lease, sublicense or otherwise transfer your Funooni Online
        account, or any access to or use of our Services, to any third party.
        <br />
        <span className="term-number">1.6</span> We may reclaim or repurpose any
        username or URL on our Services, at any time at our sole discretion, for
        any reason, including if we believe it is necessary to comply with the
        rights of a third party.
        <br />
        <span className="term-number">1.7</span> You consent to receive
        communications from us electronically, and you agree that we may
        communicate with you by posting notices about our Services and/or by
        email. You agree that all agreements, notices, disclosures and other
        communications that we provide to you electronically satisfy any legal
        requirement that such communications be in writing. You agree that all
        notices we provide to you by email will be considered received by you on
        the day that we send them. If you register for a Funooni Online account
        or otherwise provide us with an email address, you agree that we are not
        responsible for any automatic filtering that you or your network
        provider may apply to any email that we send to the email address you
        provided. If at any time you would like to withdraw your consent to
        receive emails, please use the "unsubscribe" button on the email, or
        contact us at support@funoonarab.com to do so, however it is understood
        and agreed by you that if you withdraw consent, you may not be able to
        participate in all Services offered.
        <br />
        <span className="term-number">1.8 </span>You are responsible for
        providing any equipment (such as computers or mobile devices) and
        network access necessary for you to use our Services or communicate with
        us at your own cost.
        <br />
        <br />
        <p className="lgcy-sub-title">2. Ownership</p>
        <br />
        <span className="term-number">2.1 </span> All Funooni Platform Content
        is owned by Funooni Platform or by others who have licensed their
        Content to us, and is protected by international copyright laws,
        trademark laws and/or other proprietary rights and laws. Our Services
        are also protected as a compilation and/or collective work under UAE or
        international copyright laws.
        <br />
        <span className="term-number">2.2 </span> The trademarks, service marks,
        logos, and product names displayed on or in connection with our Services
        are the registered and/or unregistered trademarks and service marks of
        Funooni Platform or third parties in UAE and/or other countries.
        <br />
        <span className="term-number">2.3</span> As between you and Funooni
        Platform, Funooni Platform owns and retains, solely and exclusively, all
        rights, title, and interest in and to our Services, the look and feel,
        design and organization of our Services, all Funooni Platform Content,
        and the compilation of all Content on our Services, including all
        copyrights, moral rights, trademark rights, trade secrets, patent
        rights, database rights, and other intellectual property and proprietary
        rights therein.
        <br />
        <span className="term-number">2.4</span> These Terms do not grant you
        any ownership over any Content, or any intellectual property rights in
        any Content, except only for any intellectual property rights that you
        may have in your User Content, which you will remain the Owner of.
        <br />
        <span className="term-number">2.5 </span>Neither these Terms nor your
        use of our Services grants you any license or permission to or under any
        copyright, trademark, or other intellectual property of Funooni Platform
        or any third party, whether by implication or otherwise.
        <br />
        <span className="term-number">2.6</span> These Terms do not grant you
        the right to use any of our trademarks, marks, logos, product names,
        domain names, or other distinctive brand features in any way.
        <br />
        <span className="term-number">2.7</span> We reserve all rights not
        expressly granted to you in these Terms.
        <br />
        <br />
        <p className="lgcy-sub-title">
          3. General Terms Applicable To The Content
        </p>
        <br />
        <span className="term-number">3.1</span> Please note that the Content
        covers a wide range of art and subject matter, is generally uncensored,
        and may include nudity or other visual or written material that some
        people may consider offensive or inappropriate for children and minors.
        If you allow your child or anyone else to use your computer or other
        device, it is solely your responsibility to prevent them from accessing
        any Content that you think is or may be inappropriate for them.
        <br />
        <span className="term-number">3.2</span> Subject to these Terms, you may
        access and view Content within our Services for your own personal,
        non-commercial use, in the context of your Funooni Platform User
        experience, in accordance with the normal functionality and restrictions
        of our Services.
        <br />
        <span className="term-number">3.3 </span>Some Services may enable you to
        post, embed or transmit some Content in a way that will be accessible to
        others on our Services or in other locations online (such as other
        websites and social media services). You may use such Services in
        accordance with their normal functionality and restrictions. However,
        the availability of such Services does not imply or give you permission
        to reproduce, distribute or otherwise use such Content in any other way,
        whether on our Services or in any other location.
        <br />
        <span className="term-number">3.4 </span>Except as expressly permitted
        by these Terms, you must not reproduce, distribute, adapt, modify,
        translate, create derivative works from, publish or otherwise use any
        Content in any way without express prior written permission from us or
        (as applicable) the appropriate third-party rights holder.
        <br />
        <span className="term-number"> 3.5 </span>Any commercial exploitation of
        any image or other Content without express prior written permission from
        us or (as applicable) the appropriate third-party rights holder,
        including any commercialized reproduction, distribution, publishing, or
        creation of derivative works, is strictly prohibited.
        <br />
        <br />
        <p className="lgcy-sub-title">4. User Content And Communications</p>
        <br />
        <span className="term-number">4.1 </span>Some Services may enable Users
        to submit, post, publish or otherwise provide User Content. Each User is
        solely responsible for all User Content that they provide on or through
        our Services.
        <br />
        <span className="term-number">4.2</span> We do not endorse or make any
        representations or warranties of any kind with respect to any User
        Content and/or any statements, ideas, advice or opinions communicated
        on, though, or in connection with our Services (whether online, offline,
        orally, in writing or otherwise) by any User and/or third party, whether
        with respect to accuracy, completeness, truthfulness, reliability or
        otherwise. You acknowledge that any use of or reliance on any User
        Content and/or any such statements, ideas, advice or opinions is solely
        at your own risk.
        <br />
        <span className="term-number">4.3</span> You acknowledge that we have no
        obligation to review or screen any User Content, and that by using our
        Services, you may be exposed to User Content that is inaccurate,
        misleading, offensive or otherwise objectionable.
        <br />
        <span className="term-number">4.4</span> Some User Content may be marked
        as "featured" (or marked with other similar language) when it is
        provided on or through our Services. Such markings are for general
        informational use only and are not an endorsement, representation or
        warranty of any kind by us.
        <br />
        <span className="term-number">4.5 </span>We reserve the right, but have
        no obligation, to monitor and/or review any and all User Content and/or
        communications transmitted on or through our Services, to enforce or
        investigate potential violations of these Terms or our other policies or
        agreements with Users, to detect, prevent or otherwise address fraud,
        security or technical issues, or to otherwise administer, improve or
        operate our Services and/or customer support, without notice or
        liability, at any time at our sole discretion. We also reserve the right
        to access, preserve and/or disclose any User Content if we believe it is
        reasonably necessary to: (i) respond to claims that any User Content
        violates the rights of third parties; (ii) comply with applicable laws,
        regulations, legal process or government requests; (iii) enforce these
        Terms; (iv) prevent or address any fraud, security or technical issues;
        or (v) protect the rights, property or safety of Funooni Platform, its
        Users, or the public.
        <br />
        <span className="term-number"> 4.6</span> If you provide User Content to
        us or post User Content on our Services, you grant us a worldwide,
        non-exclusive, royalty-free, perpetual, irrevocable and fully
        sub-licensable license to use, reproduce, modify, adapt, publish,
        translate, create derivative works from, distribute, perform and display
        such User Content (in whole or part) and/or incorporate it in other
        works in any form, media, or technology, whether now known or hereafter
        developed, for any purpose, commercial, advertising, or otherwise, in
        connection with our Services or our business (including for promoting
        and redistributing part or all of our Services).
        <br />
        <span className="term-number"> 4.7</span> You represent and warrant that
        you have all necessary rights, licenses, and permissions to grant the
        licenses described above, and that the User Content you provide or post
        does not infringe or violate any copyright, trademark, patent, trade
        secret or other intellectual property right or any privacy or publicity
        rights of any third party.
        <br />
        <span className="term-number"> 4.8</span> If you become aware of any
        User Content that you believe is inappropriate, offensive or otherwise
        violates these Terms or our other policies or agreements, please notify
        us by contacting us at support@funoonarab.com, and we will review the
        User Content in question and take appropriate action, if necessary.
        <br />
        <span className="term-number"> 4.9</span> The views expressed by Users
        in User Content or through communications on our Services are solely
        those of the Users and do not necessarily reflect our views.
        <br />
        <br />
        <p className="lgcy-sub-title">5. Third-Party Content And Links</p>
        <br />
        <span className="term-number">5.1 </span>Our Services may contain links
        to third-party websites, services, applications or other resources. We
        do not endorse or assume any responsibility for any such third-party
        websites, services, applications, or resources, and we are not
        responsible or liable for any content, products, services, or other
        materials available from or through any such third-party websites,
        services, applications, or resources.
        <br />
        <span className="term-number">5.2 </span> If you decide to access or use
        any third-party websites, services, applications, or resources, you do
        so at your own risk and you agree that we will not be responsible or
        liable for any loss or damage of any kind incurred as a result of your
        use of or reliance on any third-party websites, services, applications,
        or resources.
        <br />
        <span className="term-number"> 5.3 </span>Your dealings with or
        participation in promotions or transactions with third parties found on
        or through our Services, including payment and delivery of related goods
        or services, are solely between you and such third parties.
        <br />
        <span className="term-number"> 5.4 </span>You agree that we will not be
        responsible or liable for any loss or damage of any sort incurred as a
        result of any such dealings or as a result of the presence of such third
        parties on our Services.
        <br />
        <br />
        <p className="lgcy-sub-title">6. Use of Our Services</p>
        <br />
        <span className="term-number"> 6.1 </span>You agree to use our Services
        only for lawful purposes and in accordance with these Terms. You agree
        that you will not engage in any activity that interferes with or
        disrupts our Services or any servers or networks connected to our
        Services.
        <br />
        <span className="term-number"> 6.2 </span>You may not use our Services
        to engage in any illegal, fraudulent or harmful activities, including
        but not limited to:
        <br />
        <span className="sub-term-letter">(a) </span>impersonating any person or
        entity, or falsely stating or misrepresenting your affiliation with any
        person or entity;
        <br />
        <span className="sub-term-letter">(b) </span>engaging in any activity
        that is harmful, threatening, harassing, abusive, defamatory, vulgar,
        obscene, or otherwise objectionable;
        <br />
        <span className="sub-term-letter">(c) </span>uploading, posting,
        transmitting, or otherwise making available any content that you do not
        have the right to make available under any law or under contractual or
        fiduciary relationships;
        <br />
        <span className="sub-term-letter">(d) </span>using our Services to
        transmit, distribute, or store material that contains software viruses,
        worms, or any other harmful computer code, files, or programs;
        <br />
        <span className="sub-term-letter">(e) </span>attempting to gain
        unauthorized access to our Services, accounts, computer systems, or
        networks through hacking, password mining, or any other means;
        <br />
        <span className="sub-term-letter">(f) </span>disrupting the normal
        functioning of our Services or otherwise interfering with other Users’
        use of our Services;
        <br />
        <span className="sub-term-letter">(g) </span>engaging in any activity
        that violates any applicable law or regulation.
        <br />
        <span className="term-number"> 6.3 </span>You agree to comply with any
        additional guidelines, rules, or policies that apply to specific
        Services, including those provided in connection with our Services. Any
        violation of these Terms may result in the termination or suspension of
        your access to our Services at our sole discretion.
        <br />
        <span className="term-number"> 6.4 </span>We may, but have no obligation
        to, monitor and review your use of our Services to ensure compliance
        with these Terms, and we may take appropriate actions as we deem
        necessary to enforce these Terms.
        <br />
        <br />
        <p className="lgcy-sub-title">7. Termination</p>
        <br />
        <span className="term-number"> 7.1 </span>We may terminate or suspend
        your access to our Services at any time, without notice or liability,
        for any reason, including if we believe that you have violated these
        Terms or any applicable laws or regulations.
        <br />
        <span className="term-number"> 7.2 </span>Upon termination, your right
        to use our Services will immediately cease, and we may delete or
        deactivate your account and all related content, without any liability
        to you.
        <br />
        <span className="term-number"> 7.3 </span>Sections 2, 3, 4, 5, 6, 7, 8
        and 9 of these Terms will survive termination of your access to our
        Services.
        <br />
        <br />
        <p className="lgcy-sub-title">
          {" "}
          8. Disclaimers And Limitations Of Liability
        </p>
        <br />
        <span className="term-number"> 8.1 </span>Our Services are provided "as
        is" and "as available," without warranties or representations of any
        kind, whether express or implied. To the fullest extent permitted by
        law, we disclaim all warranties, express or implied, including but not
        limited to implied warranties of merchantability, fitness for a
        particular purpose, and non-infringement.
        <br />
        <span className="term-number"> 8.2 </span>We do not guarantee the
        accuracy, completeness, reliability, availability, or timeliness of our
        Services or any Content, and we are not responsible for any errors or
        omissions in or to the Content or for any loss or damage of any kind
        incurred as a result of your use of our Services or reliance on any
        Content.
        <br />
        <span className="term-number"> 8.3 </span>To the fullest extent
        permitted by law, we will not be liable for any direct, indirect,
        incidental, special, consequential, or punitive damages, including but
        not limited to loss of profits, data, or intangible losses, arising out
        of or related to your use or inability to use our Services or any
        Content, even if we have been advised of the possibility of such
        damages.
        <br />
        <span className="term-number"> 8.4 </span>You agree that any claim or
        cause of action arising out of or related to your use of our Services
        must be filed within one (1) year after such claim or cause of action
        arose or be forever barred.
        <br />
        <br />
        <p className="lgcy-sub-title">9. Indemnification</p>
        <br />
        <span className="term-number"> 9.1 </span>You agree to indemnify,
        defend, and hold harmless Funooni Platform, its affiliates, officers,
        directors, employees, and agents from and against any and all claims,
        liabilities, damages, losses, costs, or expenses (including reasonable
        attorneys' fees) arising out of or related to:
        <br />
        <span className="sub-term-letter">(a)</span> your use or misuse of our
        Services;
        <br />
        <span className="sub-term-letter"> (b)</span> any User Content you
        provide or post;
        <br />
        <span className="sub-term-letter"> (c)</span> your violation of these
        Terms or any applicable laws or regulations;
        <br />
        <span className="sub-term-letter">(d)</span> any infringement or
        violation of any intellectual property rights or other rights of any
        third party.
        <br />
        <span className="term-number"> 9.2 </span>We reserve the right to assume
        the exclusive defense and control of any matter subject to
        indemnification by you, in which case you agree to cooperate with us in
        asserting any available defenses.
        <br />
        <br />
        <p className="lgcy-sub-title">10. Modifications</p>
        <br />
        <span className="term-number"> 10.1 </span>We may modify these Terms at
        any time, and such modifications will be effective immediately upon
        posting the updated Terms on our Services. Your continued use of our
        Services after any modifications will constitute your acceptance of the
        modified Terms.
        <br />
        <span className="term-number"> 10.2 </span>We may also update or change
        our Services, including any functionalities, features, or Content, at
        any time without notice.
        <br />
        <br />
        <p className="lgcy-sub-title">11. Governing Law</p>
        <br />
        <span className="term-number"> 11.1 </span>These Terms and any disputes
        arising out of or related to these Terms or our Services will be
        governed by and construed in accordance with the laws of the United Arab
        Emirates, without regard to its conflict of laws principles.
        <br />
        <span className="term-number"> 11.2 </span>Any legal action or
        proceeding arising out of or related to these Terms or our Services must
        be brought exclusively in the courts located in Dubai, United Arab
        Emirates, and you consent to the jurisdiction and venue of such courts.
        <br />
        <br />
        <p className="lgcy-sub-title">12. Miscellaneous</p>
        <br />
        <span className="term-number"> 12.1 </span>If any provision of these
        Terms is found to be invalid or unenforceable, the remaining provisions
        will remain in full force and effect.
        <br />
        <span className="term-number"> 12.2 </span>These Terms constitute the
        entire agreement between you and us with respect to the subject matter
        hereof and supersede all prior or contemporaneous communications and
        proposals, whether oral, written, or electronic, between you and us with
        respect to our Services.
        <br />
        <span className="term-number"> 12.3 </span>No waiver of any term of
        these Terms will be deemed a further or continuing waiver of such term
        or any other term.
        <br />
        <span className="term-number"> 12.4 </span>You may not assign or
        transfer these Terms, by operation of law or otherwise, without our
        prior written consent. We may freely assign these Terms without
        restriction.
        <br />
        <span className="term-number"> 12.5 </span>Our failure to enforce any
        right or provision of these Terms will not be deemed a waiver of such
        right or provision.
        <br />
        <span className="term-number"> 12.6 </span>These Terms do not create any
        third-party beneficiary rights.
        <br />
        <span className="term-number"> 12.7 </span> Any questions or comments
        about these Terms or our Services can be directed to us at
        support@funoonarab.com.
        <br />
        <br />
        <p className="lgcy-sub-title">
          13. Choice Of Law And Dispute Resolution
        </p>{" "}
        <br />
        <span className="term-number"> 13.1 </span> The Terms, and your
        relationship with Funooni Platform under the Terms, shall be governed by
        UAE law. You and Funooni Platform agree to submit to the exclusive
        jurisdiction of the UAE, to resolve any legal matter arising from the
        Terms. Notwithstanding this, you agree that Funooni Platform shall still
        be allowed to apply for interdicts, injunctive remedies (or other
        equivalent types of urgent legal remedy) in any jurisdiction. <br />
        <span className="term-number"> 13.2 </span> You agree that regardless of
        any law to the contrary, each claim you might have against us arising
        out of or related to these Terms or our Services must be filed within
        the applicable statute of limitations, or one (1) year after such claim
        arose, or else such claim will be permanently barred. <br />
        <span className="term-number"> 13.3 </span> The United Nations
        Convention on Contracts for the International Sale of Goods will not in
        any way apply to our Services, these Terms, or any other agreement
        between you and us. <br />
        <br />
        <p className="lgcy-sub-title">14. International Use</p> <br />
        <span className="term-number"> 14.1 </span> If you are located outside
        UAE, you agree to ensure that your access to and use of our Services
        complies with all applicable local, state, national and international
        laws and regulations, including any laws and regulations governing the
        import or export of data, goods, services or software. <br />
        <span className="term-number"> 14.2 </span> You must not access or use
        our Services if any applicable local, state, national, or international
        law or regulation prohibits you from receiving products or services
        originating from UAE. <br />
        <br />
        <p className="lgcy-sub-title"> 15. Changes To Our Services </p> <br />
        <span className="term-number"> 15.1 </span> Our Services are updated
        frequently, and their form and functionality may change without notice.
        We reserve the right to change, modify, add, remove or discontinue any
        and all of our Services (and/or the appearance, design, functionality,
        and all other aspects of any and all of our Services), in whole or part,
        temporarily or permanently, with or without notice, for any reason or no
        reason, at any time at our sole discretion. <br />
        <span className="term-number"> 15.2 </span> We will not be liable to you
        or any third party for any damages or losses of any kind resulting
        directly or indirectly from any changes made by us to our Services,
        including any loss of business or the ability to use any product,
        service or Content. <br />
        <br />
        <p className="lgcy-sub-title">16. Changes To These Terms </p> <br />
        <span className="term-number"> 16.1 </span> Our business changes with
        time, and these Terms will change also. We reserve the right to change
        these Terms, including the right to change, modify, add or remove any
        part of these Terms, without prior notice, at any time at our sole
        discretion. <br />
        <span className="term-number"> 16.2 </span> All changes to these Terms
        will be effective when posted on our Services, or at such later date as
        may be specified in the updated Terms. <br />
        <span className="term-number"> 16.3 </span> By continuing to use our
        Services after any changes to these Terms become effective, you agree to
        such changes and the updated Terms. <br />
        <span className="term-number"> 16.4 </span> You agree that we may notify
        you of any changes to these Terms by posting the updated Terms on our
        Services, and you agree to review these Terms regularly and inform
        yourself of all applicable changes. <br />
        <span className="term-number"> 16.5 </span> If you do not agree to any
        changes made by us to these Terms, you will no longer be permitted to
        use our Services. <br />
        <span className="term-number"> 16.6 </span> Changes to these Terms will
        not apply to any claim or dispute that arose before such changes became
        effective, all of which will remain subject to the version of these
        Terms in effect at the time that such claim or dispute arose. <br />
        <span className="term-number"> 16.7 </span> We will not be liable to you
        or any third party for any damages or losses of any kind resulting
        directly or indirectly from any changes made by us to these Terms,
        including any loss of business or the ability to use any product,
        service or content. <br />
        <br />
        <p className="lgcy-sub-title">17. Termination </p> <br />
        <span className="term-number"> 17.1 </span> You may stop using our
        Services at any time, subject to any other agreements between you and
        us. <br />
        <span className="term-number"> 17.2 </span> Regardless of any other
        statement in these Terms, we reserve the right to terminate these Terms,
        to terminate your account (if you have an account), and/or to limit,
        terminate or suspend your access to or use of any or all of our
        Services, at any time at our sole discretion, without notice or
        liability, for any reason or no reason, including if you violate the
        letter or spirit of these Terms. The termination of these Terms shall
        not affect such of the provisions of these Terms as expressly provide
        that they will operate after any such termination or which of necessity
        must continue to have effect after such expiration or termination,
        notwithstanding that the clauses themselves do not expressly provide for
        this. After any termination of these Terms and/or any termination of
        your access to or use of our Services, the following will survive and
        remain in full force and effect: (i) all outstanding obligations you may
        have to us under these Terms or otherwise; (ii) all remedies for breach
        of these Terms. <br />
        <br />
        <p className="lgcy-sub-title">18. Copyright Policy </p> <br />
        <span className="term-number"> 18.1 </span> We respect the intellectual
        property rights of others and expect Users to do the same. We will
        respond to notices of claimed copyright infringement that are properly
        provided to our designated copyright agent ("Copyright Agent"), whose
        contact information is listed below. <br />
        <span className="term-number"> 18.2 </span> We reserve the right to
        remove or disable access to any Content on our Services claimed to be
        infringing, at any time at our sole discretion, without notice or
        liability. In appropriate circumstances, we will also terminate Users
        and account holders who are repeat infringers. <br />
        <span className="term-number"> 18.3 </span> If you believe that Content
        on our Services has been used in a way that constitutes copyright
        infringement, please notify us with a written notice containing all of
        the following information (“Copyright Infringement Notice”): <br />
        <span className="multi-term-number">18.3.1 </span>A physical or
        electronic signature of a person authorized to act on behalf of the
        owner of an exclusive right that is allegedly infringed; <br />
        <span className="multi-term-number"> 18.3.2 </span> Identification of
        the copyrighted work claimed to have been infringed; <br />
        <span className="multi-term-number"> 18.3.3</span> Identification of the
        Content that is claimed to be infringing or to be the subject of
        infringing activity, and information reasonably sufficient to permit us
        to locate such Content on our Services; <br />
        <span className="multi-term-number"> 18.3.4</span> Information
        reasonably sufficient to permit us to contact you, such as an address,
        telephone number, and, if available, an email address; <br />
        <span className="multi-term-number"> 18.3.5</span> a statement that the
        information in your notice is accurate and that you are authorized to
        act on behalf of the owner of an exclusive right that is allegedly
        infringed. <br />
        <span className="term-number">18.4</span> If you are the provider of
        Content that was removed (or to which access was disabled) and you
        believe that such Content is not infringing, or that the use of such
        Content is authorized by the copyright owner, the copyright owner's
        agent, or the law, you may provide us with a written counter-notice
        containing all of the following information ("Counter-Notice"): <br />
        <span className="multi-term-number"> 18.4.1 </span> your physical or
        electronic signature; <br />
        <span className="multi-term-number"> 18.4.2 </span> identification of
        the Content that was removed or to which access was disabled, and the
        location at which such Content appeared on our Services before it was
        removed or access to it was disabled; <br />
        <span className="multi-term-number">18.4.3 </span> a statement that you
        have a good faith belief that the Content was removed or disabled as a
        result of mistake or misidentification of the Content; and <br />
        <span className="multi-term-number">18.4.4 </span> your name, address,
        and telephone number, and a statement that you consent to the
        jurisdiction of the court referred to in clause 15.1, and that you will
        accept service of process from the person who provided the notice of
        claimed infringement or an agent of such person. <br />
        <span className="term-number"> 18.5 </span>Our Copyright Agent
        designated to receive Copyright Infringement Notices and Counter-Notices
        may be contacted as follows: By email: support@funoonarab.com, Attn:
        Copyright Agent. <br />
        <span className="term-number"> 18.6 </span>If you provide us with a
        Copyright Infringement Notice, you agree that we may send a copy of it
        (including your name and contact information) to the provider of the
        disputed Content. <br />
        <br />
        <p className="lgcy-sub-title">19. Miscellaneous </p> <br />
        <span className="term-number"> 19.1 </span>These Terms and other
        applicable agreements with us, and any other terms and conditions
        incorporated into these Terms by reference, together with any other
        written agreements that you may enter into with us regarding our
        Services, constitutes the entire agreement and understanding between you
        and us regarding our Services, and supersedes all prior agreements and
        understandings regarding the subject matter of these Terms. <br />
        <span className="term-number"> 19.2 </span>The failure to enforce any
        provision of these Terms will not be deemed a waiver of future
        enforcement of that or any other provision. Any waiver of any provision
        of these Terms will be effective only if in writing and signed by an
        authorized representative of Funooni Platform. <br />
        <span className="term-number"> 19.3 </span>If any provision of these
        Terms is found to be invalid, illegal, or unenforceable, such provision
        will be construed in a manner that most closely reflects the original
        intent of the parties, and the remaining provisions will continue in
        full force and effect. <br />
        <span className="term-number"> 19.4 </span>You may not assign, delegate,
        or transfer your rights or obligations under these Terms without our
        prior written consent. Any attempted assignment, delegation or transfer
        in violation of these Terms will be null and void. We may freely assign,
        delegate or transfer our rights and obligations under these Terms.{" "}
        <br />
        <span className="term-number"> 19.5 </span>You agree that no third-party
        beneficiaries will be created by these Terms. <br />
        <span className="term-number"> 19.6 </span>All provisions of these Terms
        that by their nature should survive termination of these Terms, will
        survive such termination. <br />
        <span className="term-number"> 19.7 </span>Headings are for convenience
        only and do not have any legal effect.
        <br />
        <span className="term-number"> 19.8 </span> Any notices provided by
        Funooni Platform to you under these Terms will be delivered to you by
        email or by posting a notice on our Services.
        <br />
        <span className="term-number"> 19.9 </span> If you have any questions or
        concerns about these Terms, please contact us at:
        support@funoonarab.com.
      </p>
    ),
  },
];
