import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import { useState, useEffect, Suspense, lazy } from "react";
import "./App.scss";
import { Footer, Preloader } from "./components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ScrollToTop, useToFindItem } from "./services";
import {
  About,
  Auth,
  Fashion,
  FashionTool,
  Legal,
  Media,
  ResetPassword,
  Letter,
  Galllary,
} from "./routes";
import CertificateTool from "./routes/CertificateTool/CertificateTool";
import ArtistProfile from "./routes/CertificateTool/ArtistProfile";
import { PhotoDetail, ProfileDetails } from "./components/Galary";

const Article = lazy(() => import("./routes/Article/Article"));
const Home = lazy(() => import("./routes/Home/Home"));
const NavBar = lazy(() => import("./components/Common/NavBar/NavBar"));
const MarketPage = lazy(() => import("./routes/MarketPage/MarketPage"));
const User = lazy(() => import("./routes/User/User"));
const Art = lazy(() => import("./routes/item/Arts/Art"));
const Artist = lazy(() => import("./routes/item/Artists/Artist"));
const Admin = lazy(() => import("./admin/Admin"));
const NotFound = lazy(() => import("./routes/NotFound/NotFound"));
const PhyArt = lazy(() => import("./routes/item/Arts/PhyArt"));

function App() {
  const location = useLocation();
  const [showCookieConsent, setShowCookieConsent] = useState(
    !localStorage.getItem("cookieConsent")
  );

  const isAccountPageOrLetter =
    location.pathname === "/account" ||
    location.pathname === "/admin" ||
    location.pathname === "*" ||
    location.pathname.startsWith("/loa/");

  const isLetterPage = location.pathname.startsWith("/loa/");

  const handleAcceptCookies = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowCookieConsent(false);
  };

  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        className="toast"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {!isLetterPage && showCookieConsent && (
        <div className="cookie-consent">
          <div className="cookie-consent__content">
            <p>
              We use cookies to improve your experience and personalize
              marketing. By clicking "Accept All", you agree to our{" "}
              <Link to={"/legal/terms-of-use"}>user policy</Link>.
            </p>
            <div className="cookie-consent__actions">
              <Link to={"/legal/terms-of-use"}>Manage Terms of Use</Link>
              <button onClick={handleAcceptCookies}>Accept All</button>
            </div>
          </div>
        </div>
      )}
      <Suspense fallback={<Preloader />}>
        {!isAccountPageOrLetter && <NavBar />}
        <ScrollToTop />
        <Routes>
          <Route path="/aGVsbG8gd29ybGQh" element={<Admin />} />
          <Route path="/" element={<Home />} />
          <Route path="/nfts" element={<MarketPage marketType="nfts" />} />
          <Route
            path="/artworks"
            element={<MarketPage marketType="artwork" />}
          />
          <Route path="/artists" element={<MarketPage marketType="artist" />} />
          <Route path="/media" element={<Media />} />
          <Route path="/media/:id" element={<Article />} />
          <Route
            path="/nft/:idhex"
            element={
              <DataWrapper
                key={window.location.pathname}
                type="nft"
                Component={Art}
              />
            }
          />
          <Route
            path="/artist/:idhex"
            element={
              <DataWrapper
                key={window.location.pathname}
                type="artist"
                Component={Artist}
              />
            }
          />
          <Route
            path="/artwork/:idhex"
            element={
              <DataWrapper
                key={window.location.pathname}
                type="artwork"
                Component={PhyArt}
              />
            }
          />
          <Route path="/fashion" element={<Fashion />} />
          <Route path="/fashion_tool" element={<FashionTool />} />
          <Route path="/about" element={<About />} />
          <Route path="/profile" element={<User />} />
          <Route path="/logout" />
          <Route path="/legal/:section" element={<Legal />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/password_reset" element={<ResetPassword />} />
          <Route
            path="/loa/:contractAddress/:tokenId/:artist_id"
            element={<Letter />}
          />
          <Route path="/certificate-tool" element={<CertificateTool />} />
          <Route path="/verify-profile" element={<ArtistProfile />} />
          <Route path="/GazaPhoto" element={<Galllary />} />
          <Route path="/GazaPhoto/:id" element={<PhotoDetail />} />{" "}
          <Route path="/profile/:name" element={<ProfileDetails />} />
        </Routes>
        {!isAccountPageOrLetter && <Footer />}
      </Suspense>
    </div>
  );
}

function DataWrapper({ type, Component }) {
  const [loading, setLoading] = useState(true);
  const [pageID, setPageID] = useState();
  const { idhex } = useParams();
  const [name, id] = idhex.split("-");

  useEffect(() => {
    setPageID(id);
  }, [id]);

  const fetchData = useToFindItem(type, pageID);

  useEffect(() => {
    if (fetchData) {
      setLoading(false);
    }
  }, [fetchData]);

  if (loading) {
    return <Preloader />;
  }
  return <Component data={fetchData} />;
}

export default App;
