import React, { useEffect, useState } from "react";
import "./Media.scss";
import axios from "axios";
import { hostURL } from "../../services";
import { Slider1, BoxCard1 } from "../../components";

const Media = () => {
  const [mediaData, setMediaData] = useState([]);

  useEffect(() => {
    const fetchMediaData = async () => {
      try {
        const response = await axios.get(`${hostURL}media`);
        setMediaData(response.data);
      } catch (error) {
        console.error("Error fetching media data:", error);
      }
    };

    fetchMediaData();
  }, []);

  return (
    <div className="media-home">
      <Slider1 />

      <div className="articles-box-container">
        {mediaData.map((box, index) => (
          <BoxCard1
            id={box.id}
            key={index}
            type={box.type}
            image={box.img_url}
            title={box.title}
            content={box.short_description}
            author={box.author}
            date={box.created_at}
            maxWords={20}
          />
        ))}
      </div>
    </div>
  );
};

export default Media;
