import React from "react";
import { useParams } from "react-router-dom";
import { PhotoData } from "../Photo/PhotoData";
import "./PhotoDetail.css";

const PhotoDetail = () => {
  const { id } = useParams();
  const photo = PhotoData.find((item) => item.id === parseInt(id));

  if (!photo) {
    return <div className="photo-detail">Photo not found</div>;
  }

  return (
    <div className="photo-detail">
      <h1>{photo.name}</h1>
      <div className="photo-detail-container">
        <div className="photo-details2">
          <div className="photo-image-container">
            <img src={photo.image} alt={photo.name} className="photo-image" />
          </div>
          <div className="photo-info">
            <p>
              <strong>Photographer:</strong> {photo.namePhotographer}
            </p>
            <p>
              <strong>Size:</strong> {photo.size}
            </p>
            <p>
              <strong>Price:</strong> {photo.price}
            </p>
            <a
              href={photo.StripLink}
              target="_blank"
              rel="noopener noreferrer"
              className="back-button2"
            >
              Buy Now
            </a>
          </div>
        </div>
        <div className="photo-details2 photo-details3">
          <p>
            <strong>Description</strong>
          </p>
          {photo.desc}
        </div>
      </div>
    </div>
  );
};

export default PhotoDetail;
