import React, { useState, useEffect, useRef } from "react";
import "./Art.scss";
import {
  formatName,
  hostURL,
  toTitleCase,
  useToFindItem,
  useAuth,
} from "../../../services";
import { Link } from "react-router-dom";
import Web3 from "web3";
import axios from "axios";
import MyNFT from "../../../contracts/MyNFT.json";
import { toast } from "react-toastify";
import { Modal, SinglePageField } from "../../../components";

const API_BASE_URL = hostURL;

const Art = ({ data }) => {
  const [artItem, setArtItem] = useState({});
  const [shareText, setShareText] = useState("Share");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMetaMaskModalOpen, setIsMetaMaskModalOpen] = useState(false);
  const [account, setAccount] = useState("");
  const [contract, setContract] = useState(null);
  const previewRef = useRef(null);
  const { isAuthenticated, authenticateWeb3 } = useAuth();

  const [usdPrice, setUsdPrice] = useState(null);

  const ownerData = useToFindItem("artist", data.artist?.id);

  useEffect(() => {
    if (data) {
      setArtItem(data);
    }
  }, [data]);

  useEffect(() => {
    const fetchUsdPrice = async () => {
      if (!artItem.price) return;

      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
        );
        const ethToUsdRate = response.data.ethereum.usd;
        const priceInUsd = (artItem.price * ethToUsdRate).toFixed(2);
        setUsdPrice(priceInUsd);
      } catch (error) {
        console.error("Error fetching ETH/USD rate:", error);
        toast.error("Failed to fetch ETH to USD conversion rate.");
      }
    };

    fetchUsdPrice();
  }, [artItem.price]);

  const handlePurchase = async () => {
    if (!window.ethereum) {
      toast.error(
        "MetaMask is not installed. Please install the MetaMask extension in your browser to proceed."
      );
      return;
    }

    const web3 = new Web3(window.ethereum);

    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];

      const contractInstance = new web3.eth.Contract(
        MyNFT.abi,
        "0xC4634A048ba1FC582Bfbbd7d65Fe6A532b778a28"
        // "0x871235c4B67A8200dad703F61198B135345B3396"
      );

      if (!isAuthenticated) {
        await authenticateWeb3(account);
        toast.info(
          "Web3 authentication completed. Please try purchasing again."
        );
        return;
      }

      const tokenId = data.tokens_on_sale[data.tokens_on_sale.length - 1];
      const price = Web3.utils.toWei(data.price.toString(), "ether");

      const gasPrice = await web3.eth.getGasPrice();
      let gasLimit;

      try {
        gasLimit = await contractInstance.methods
          .purchaseNFT(tokenId)
          .estimateGas({
            from: account,
            value: price,
          });
      } catch (error) {
        if (error.message.includes("insufficient funds")) {
          toast.error(
            "Insufficient funds. Please ensure you have enough ETH in your wallet."
          );
          return;
        }
        throw error;
      }

      const tx = await contractInstance.methods.purchaseNFT(tokenId).send({
        from: account,
        value: price,
        gas: gasLimit,
        gasPrice: gasPrice,
      });

      const receipt = await web3.eth.getTransactionReceipt(tx.transactionHash);

      if (receipt && receipt.status) {
        toast.success(`Token ${tokenId} purchased!`);
        const nftId = artItem.id;

        console.log(
          "receipt:",
          receipt,
          "tx.transactionHash:",
          tx.transactionHash
        );

        try {
          const response = await axios.post(`${API_BASE_URL}nft/buy`, null, {
            params: {
              nft_id: nftId,
              token_id: tokenId,
              tx_hash: tx.transactionHash,
            },
            withCredentials: true,
          });
          // console.log("Token removed from sale:", response.data);
        } catch (error) {
          // console.error("Error removing token from sale:", error);
          // toast.error("Failed to remove token from sale");
        }
      } else {
        toast.error("Transaction failed");
      }
    } catch (error) {
      toast.error(
        "Authentication error. Please check the notification in your MetaMask wallet. A request may have already been sent."
      );
      console.error("Error:", error);
    }
  };

  const handleShare = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setShareText("Copied");
        setTimeout(() => setShareText("Share"), 3000);
      })
      .catch((err) => console.error("Failed to copy the link: ", err));
  };

  return (
    <div className="art-single-page">
      <div className="ars-container">
        <div className="ars-preview-container">
          <div
            className="ars-preview"
            ref={previewRef}
            style={{
              backgroundImage: `url(${artItem.prev_img_url})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
            onClick={() => setIsModalOpen(true)}
          ></div>
        </div>
        <div className="ars-buffer"></div>
        <div className="ars-info">
          <div className="ars-title">
            <div className="art-title-name">{artItem.name}</div>
            <div className="share-btn" onClick={handleShare}>
              {shareText}
            </div>
          </div>
          <div className="ars-title-under">
            <div className="ars-author">
              Artist: {toTitleCase(artItem.artist?.name) || "Unknown Artist"}
            </div>
            {artItem.rarity !== "open edition" && (
              <div className="ars-rarity">
                {artItem.tokens_on_sale?.length || 0}/{artItem.rarity}
              </div>
            )}
          </div>

          <div className="purchase_btn" onClick={handlePurchase}>
            <div className="stripe_link">Own it now - {artItem.price} ETH</div>
          </div>
          <div className="curency-converter"> ~ {usdPrice} USD</div>
        </div>
      </div>
      <SinglePageField spf_title="About the work">
        <div place="content">
          <div className="static-information">
            <ul className="ars-net">
              <li className="ars-field">
                <div className="ars-inf-field">Name</div>
                <div className="ars-inf-subfield">
                  {toTitleCase(artItem.name)}
                </div>
              </li>
              <li className="ars-field">
                <div className="ars-inf-field">Collection</div>
                <div className="ars-inf-subfield">
                  {toTitleCase(artItem.collection)}
                </div>
              </li>
              {artItem.rarity !== "open edition" && (
                <li className="ars-field">
                  <div className="ars-inf-field">Rarity</div>

                  <div className="ars-inf-subfield">1/{artItem.rarity}</div>
                </li>
              )}

              <li className="ars-field">
                <div className="ars-inf-field">Animation</div>
                <div className="ars-inf-subfield">
                  {artItem.is_animated ? "animated" : "non-animated"}
                </div>
              </li>
              <li className="ars-field">
                <div className="ars-inf-field">Categories</div>
                <div className="ars-inf-subfield">
                  <ul>
                    {artItem.categories?.map((item, index) => (
                      <li key={index}>#{item}</li>
                    ))}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="description-field">
            <div className="description-container">
              <div className="ars-inf-field">Description</div>
              <div className="sector-content ars-inf-subfield">
                {artItem.description}
              </div>
            </div>
          </div>
        </div>
      </SinglePageField>
      <SinglePageField spf_title="About the author">
        <div className="linker" place="extraField">
          {ownerData ? (
            <Link to={`/artist/${formatName(ownerData.name)}-${ownerData.id}`}>
              <div>{toTitleCase(ownerData.name)}</div>
              <div
                className="artst-ava"
                style={{
                  backgroundImage: `url(${ownerData.img_url})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Link>
          ) : (
            <div>Loading author data...</div>
          )}
        </div>
        <div place="content">
          {ownerData ? (
            <div className="ars-full-bio">{ownerData.bio}</div>
          ) : (
            <div>Loading biography...</div>
          )}
        </div>
      </SinglePageField>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        artItem={artItem}
        source_item={artItem.img_url || null}
      />
    </div>
  );
};

export default Art;
