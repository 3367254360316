import React from "react";
import { Link } from "react-router-dom";
import "./BoxCard1.scss";

const BoxCard1 = ({
  id,
  image,
  type,
  title,
  author,
  content,
  date,
  maxWords,
}) => {
  // Function to format the date
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString)
      .toLocaleDateString("en-US", options)
      .toUpperCase();
    return formattedDate;
  };

  const truncatedContent = content
    ? content.split(" ").slice(0, maxWords).join(" ") +
      (content.split(" ").length > maxWords ? "..." : "")
    : "";

  return (
    <div className="articles-box-card">
      <Link to={`/media/${id}`} className="box-link">
        <div className="imgg">
          <img src={image} alt={title} className="box-image" />
        </div>
        <div className="box-details">
          <div className="box-sub-details">
            <h3 className="box-title">{title}</h3>
            <p className="box-subtitle">
              {type} by {author}
            </p>
          </div>
          <p className="box-content">{truncatedContent}</p>
          <Link to={`/media/${id}`}>
            <button className="box-toggle">Read more</button>
          </Link>
          <p className="box-date">{formatDate(date)}</p>
        </div>
      </Link>
    </div>
  );
};

export default BoxCard1;
