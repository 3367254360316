import {
  imgg1,
  imgg2,
  imgg3,
  imgg4,
  imgg5,
  imgg6,
  imgg7,
  imgg8,
  imgg9,
  imgg10,
  imgg11,
  imgg12,
  imgg13,
  imgg14,
  imgg15,
  imgg16,
  imgg17,
  imgg18,
  imgg19,
  imgg20,
  imgg21,
  imgg22,
  imgg23,
  imgg24,
  imgg25,
  imgg26,
  imgg27,
  imgg28,
} from "../../../assets";

export const PhotoData = [
  {
    name: "Wide open",
    image: imgg7,
    price: "180 USD",
    namePhotographer: "Fadi Badwan",
    size: "28X42",
    StripLink: "https://buy.stripe.com/8wM9Cx0LS5SWdSU5nP",
    desc: "A young child clings to a guardian, their wide eyes reflecting both vulnerability and resilience in the face of unimaginable suffering. Set against the backdrop of ongoing conflict in Gaza, this moment captures the stark reality of childhood amidst chaos, where innocence remains unbroken despite the horrors surrounding them.",
  },
  {
    name: "Collective Echoes",
    image: imgg14,
    price: "210 USD",
    namePhotographer: "Jehad Al Shrafi",
    size: "28X42",
    StripLink: "https://buy.stripe.com/7sI01XfGMeps168dUs",
    desc: "A group of young Palestinian girls unite in a striking display of strength. Their expressions convey a blend of determination and vulnerability, reflecting the resilience of a generation facing the challenges of displacement. Set against a backdrop that hints at their cultural identity, this moment embodies solidarity amidst adversity.",
  },
  {
    name: "Yet Again",
    image: imgg20,
    price: "140 USD",
    namePhotographer: "Ismael Abu Dayyah",
    size: "21X31",
    desc: "Palestinian children in their parents' car, first displaced from northern Gaza and later from neighborhoods in Khan Yunis, gather their belongings once more, forced to flee yet again—this time to Rafah—after Israel’s displacement announcement. Seeking safety, they embark on a journey with no refuge in sight.",
    StripLink: "https://buy.stripe.com/9AQ9CxgKQ2GK024cQu",
  },
  {
    name: "Anticipation",
    image: imgg2,
    price: "170 USD",
    namePhotographer: "Mahmoud Abu Hamda",
    size: "21X31",
    StripLink: "https://buy.stripe.com/bIYaGB1PWftwcOQg2o",
    desc: "In one of the shelters in Khan Yunis, a girl sits beside her grandmother, silently gazing at a burning pot in front of them. The anticipation and hunger are reflected in her wide eyes, while her grandmother prepares whatever food is available amidst a severe food crisis. This humanitarian moment embodies the suffering of the displaced, as waiting for a meal becomes a new chapter in their daily struggle for survival.",
  },
  {
    name: "Ashes of Devotion",
    image: imgg25,
    price: "150 USD",
    namePhotographer: "Haitham Nour Al-Deen",
    size: "21X31",
    desc: "Palestinians praying next to one of the mosques that was destroyed by Israeli shelling in Gaza, demonstrating their Faith in the Face of Destruction.",
    StripLink: "https://buy.stripe.com/9AQ7up66c958aGIg2L",
  },
  {
    name: "Displaced shadows",
    image: imgg13,
    price: "160 USD",
    namePhotographer: "Jehad Al Shrafi",
    size: "21X31",
    StripLink: "https://buy.stripe.com/7sI9Cx9ioftwdSU5nV",
    desc: "Palestinians displaced by the Israeli bombardment of the Gaza Strip walk through a street market in Khan Younis, southern Gaza Strip.",
  },
  {
    name: "The Pillar",
    image: imgg8,
    price: "240 USD",
    namePhotographer: "Fadi Badwan",
    size: "60X90",
    StripLink: "https://buy.stripe.com/eVa2a5eCI6X05mo7vY",
    desc: "An elderly woman stands amidst the makeshift tents of a displacement camp in Gaza, embodying the strength and endurance of those who have faced unimaginable loss. Her weathered hands and determined gaze tell a story of survival against the backdrop of conflict. As the sun sets, casting a warm glow over the camp, she represents the unwavering spirit of a community striving to maintain its dignity and identity in the face of overwhelming adversity.",
  },
  {
    name: "Fragments of Care",
    image: imgg15,
    price: "230 USD",
    namePhotographer: "Jehad Al Shrafi",
    size: "42X60",
    StripLink: "https://buy.stripe.com/fZe2a57aga9cdSU8A9",
    desc: "Khan Younis pharmacists open makeshift shops amid Gaza’s collapsing health system Dr. Mohammed Sahweil works at the makeshift Al-Shifa Pharmacy in Khan Younis, southern Gaza Strip. Sahweil, a Palestinian pharmacist who arrived in Khan Younis after fleeing Rafah, opened the makeshift pharmacy amid a lack of available health services in the area.",
  },
  {
    name: "Aid in the Void",
    image: imgg16,
    price: "170 USD",
    namePhotographer: "Jehad Al Shrafi",
    size: "21X31",
    StripLink: "https://buy.stripe.com/6oE7up0LSchkaGI3fQ",
    desc: "Women move through the remnants of a makeshift camp, surrounded by piles of debris and discarded belongings. The scene is marked by a backdrop of barbed wire and the remnants of shelter materials, illustrating the precarious living conditions faced by those displaced by conflict. The chaos of the environment highlights the ongoing challenges of survival and the efforts to navigate daily life within an unstable setting.",
  },
  {
    name: "Forced Departure",
    image: imgg17,
    price: "270 USD",
    namePhotographer: "Jehad Al Shrafi",
    size: "60X90",
    StripLink: "https://buy.stripe.com/aEUaGB0LS3KOeWYaIj",
    desc: "Palestinians are seen fleeing the southern Gaza city of Rafah amidst an Israeli ground and air offensive on May 28, 2024. A child peeks out from the car, while his dog rests comfortably in the driver's lap, symbolizing the stark contrast between the everyday and the dire circumstances surrounding them.",
  },
  {
    name: "Returning to Remanence",
    image: imgg18,
    price: "190 USD",
    namePhotographer: "Jehad Al Shrafi",
    size: "28X42",
    StripLink: "https://buy.stripe.com/00geWRgKQ9584ik7w8",
    desc: "Displaced Palestinians return to their homes in the northern Gaza Strip on Monday, Jan. 27, 2025, following Israel’s decision to allow thousands of them to go back for the first time since the early weeks of the 15-month war with Hamas.",
  },
  {
    name: "Fleeting Joy",
    image: imgg19,
    price: "150 USD",
    namePhotographer: "Ismael Abu Dayyah",
    size: "21X31",
    StripLink: "https://buy.stripe.com/4gwg0V8ek2GK2acg2F",
    desc: "A displaced child in Gaza practices parkour beside the makeshift camps along the Philadelphia Corridor, built in the shadow of an impending invasion of Rafah—finding fleeting moments of joy amidst the ruins of war.",
  },
  {
    name: "Elements",
    image: imgg9,
    price: "190 USD",
    namePhotographer: "Fadi Badwan",
    size: "28X42",
    StripLink: "https://buy.stripe.com/fZe7upbqw958dSUeYr",
    desc: "A displaced woman on the beach in Deir al-Balah city, sitting in front of her dilapidated tent facing the bitter cold, determinedly lighting a fire to prepare food. Despite the harsh conditions and challenges of daily life, she remains optimistic, embodying true steadfastness in the face of the suffering of displacement and homelessness.",
  },
  {
    name: "Seeking Connection Through Faith",
    image: imgg21,
    price: "140 USD",
    namePhotographer: "Ismael Abu Dayyah",
    size: "21X31",
    desc: "Palestinian children in a displacement camp in Rafah peer through a net barrier, watching a preacher deliver a sermon during Ramadan—too young to grasp the weight of war, yet drawn to a moment of gathering and faith in the midst of displacement.",
    StripLink: "https://buy.stripe.com/00g8ytgKQ8142acg2H",
  },
  {
    name: "Rubble to Reflection",
    image: imgg22,
    price: "140 USD",
    namePhotographer: "Ismael Abu Dayyah",
    size: "21X31",
    desc: "Palestinian children, deprived of their childhood and toys, find joy in whatever objects they can turn into playthings. In a makeshift tent at Nasser Medical Complex in Khan Yunis, a child mimics the photographer—briefly escaping the hardships of displacement through a moment of innocent joy. Photo taken during the war in Gaza Strip.",
    StripLink: "https://buy.stripe.com/7sIdSN3Y43KO02417O",
  },
  {
    name: "Old Enough",
    image: imgg23,
    price: "160 USD",
    namePhotographer: "Ismael Abu Dayyah",
    size: "21X31",
    desc: "An innocent child stands beside a tent set up by their family at Nasser Medical Complex in Khan Yunis, displaced by Israeli forces—too young to understand war, yet already living its consequences",
    StripLink: "https://buy.stripe.com/3cs6ql2U0gxA6qsdUB",
  },
  {
    name: "Above the Rubble",
    image: imgg1,
    price: "160 USD",
    namePhotographer: "Mahmoud Abu Hamda",
    size: "21X31",
    StripLink: "https://buy.stripe.com/14k1611PWdlocOQ6rN",
    desc: "Amid the ruins of their destroyed home in Rafah, children play, weaving threads of hope from the debris left by devastation. Scattered stones become toys, and rubble transforms into a playground for their innocence. This poignant scene captures the stark contrast between the harshness of reality and the purity of childhood.",
  },
  {
    name: "Escapism",
    image: imgg10,
    price: "150 USD",
    namePhotographer: "Fadi Badwan",
    size: "21X31",
    StripLink: "https://buy.stripe.com/5kAdSNams5SW7uw03y",
    desc: "A young boy relaxes on a makeshift raft, embodying a fleeting moment of tranquility amidst chaos. With his head resting back and a look of contemplation, he finds solace in the gentle waves, a stark contrast to the turmoil surrounding his life. The simplicity of his surroundings highlights the resilience of childhood spirit, as he seeks refuge on the water, momentarily escaping the harsh realities of displacement and conflict.",
  },
  {
    name: "Shadows of Childhood",
    image: imgg3,
    price: "190 USD",
    namePhotographer: "Mahmoud Abu Hamda",
    size: "31X21",
    StripLink: "https://buy.stripe.com/7sI2a566cftw1689E1",
    desc: "In the yard of a displacement school, children play among hanging laundry, defying the harshness of their circumstances. Their large shadows cast on the ground create a powerful image of childhood striving to cling to life despite the scars of war. In this imposed environment, they find solace in play, escaping their difficult reality and showcasing the resilience of innocence in the face of tragedy.",
  },
  {
    name: "A storm within",
    image: imgg4,
    price: "220 USD",
    namePhotographer: "Mahmoud Abu Hamda",
    size: "42X60",
    StripLink: "https://buy.stripe.com/4gw8ytamsepsg1203s",
    desc: "On the shores of Gaza, amidst tumultuous waves under a sunset sky, fishermen engage in their daily battle with the sea. In a small boat, they grip their oars tightly, striving to extract their livelihood from the heart of the crashing waves. Their relationship with the sea transcends mere profession; it is a narrative of patience and struggle, where the relentless waves serve as both adversary and ally.",
  },
  {
    name: "Dream with Me",
    image: imgg5,
    price: "180 USD",
    namePhotographer: "Mahmoud Abu Hamda",
    size: "28X42",
    StripLink: "https://buy.stripe.com/dR6aGBdyEa9c7uw7vV",
    desc: "On the beach of Gaza, where dreams know no bounds, two children sit in a small boat crafted from plastic, wielding wooden sticks as paddles. Despite the simplicity of their vessel and limited resources, their passion for the sea propels them into a world of adventure, as if they were aboard a grand ship. In their eyes, hope shines brightly, and their small movements embody the spirit of childhood unconfined by the harsh realities surrounding them.",
  },
  {
    name: "Absence",
    image: imgg6,
    price: "180 USD",
    namePhotographer: "Mahmoud Abu Hamda",
    size: "28X42",
    StripLink: "https://buy.stripe.com/8wM4idams5SWaGIcQg",
    desc: "On a bustling road in front of a shelter school in central Gaza, a father sits with his child on a simple cart, silently observing the flow of cars and passersby. His weary features and burdened eyes reveal the heavy toll of displacement. In this quiet moment, he strives to comprehend the new reality imposed upon them after their forced departure from the northern part of the strip, as his young child sits by his side, sharing in this poignant experience.",
  },
  {
    name: "Defying Limits",
    image: imgg24,
    price: "180 USD",
    namePhotographer: "Haitham Nour Al-Deen",
    size: "42X60",
    desc: "Palestinian youth on the beach of Gaza, finding ways to entertain themselves amidst the ongoing conflict in the city of Khan Yunis in southern Gaza.",
    StripLink: "https://buy.stripe.com/cN2aGB3Y45SW3egbMu",
  },
  {
    name: "The Fire",
    image: imgg11,
    price: "160 USD",
    namePhotographer: "Fadi Badwan",
    size: "28X42",
    StripLink: "https://buy.stripe.com/28oaGBeCI3KO2ac9E9",
    desc: "An injured Palestinian girl who is Internally displaced from their home in the north of Gaza to Deir El Balah in the middle of Gaza Strip. Rahaf helps her mother make food using logs and wood fire.",
  },
  {
    name: "Somewhere Under the Rainbow of Displacement",
    image: imgg26,
    price: "230 USD",
    namePhotographer: "Haitham Nour Al-Deen",
    size: "60X90",
    desc: "Palestinian children performing the Eid al-Fitr prayer in the open air next to the tents of displaced people, with a rainbow appearing behind them, in the city of Khan Yunis in southern Gaza.",
    StripLink: "https://buy.stripe.com/28og0Vams6X01683g0",
  },
  {
    name: "Silent Narratives",
    image: imgg27,
    price: "140 USD",
    namePhotographer: "Haitham Nour Al-Deen",
    size: "21X31",
    desc: "Palestinian children gather next to a shelter camp for displaced people, watching a movie for the first time during the war on Gaza, due to power outages caused by the blockade imposed on the Gaza Strip.",
    StripLink: "https://buy.stripe.com/5kAeWRamsa9c5moeYJ",
  },
  {
    name: "Not enough",
    image: imgg28,
    price: "190 USD",
    namePhotographer: "Haitham Nour Al-Deen",
    size: "42X60",
    desc: "A Palestinian girl running at night next to the tents of displaced people inside one of the shelter camps, as all children in Gaza have been deprived of play, education, and entertainment due to the war on Gaza.",
    StripLink: "https://buy.stripe.com/eVa2a5amsbdg5mo8Am",
  },
  {
    name: "A Hope for Return",
    image: imgg12,
    price: "160 USD",
    namePhotographer: "Fadi Badwan",
    size: "21X31",
    StripLink: "https://buy.stripe.com/cN2bKF2U0gxAg12bMi",
    desc: "The return of the displaced from the south to the north of the Gaza Strip: Despite the difficult circumstances, hope still beats in their hearts.",
  },
].map((item, index) => ({
  id: index + 1,
  ...item,
}));
