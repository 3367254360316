import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthContext, WalletContext } from "./contexts/index";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <BrowserRouter>
      <AuthContext>
        <WalletContext>
          <App />
        </WalletContext>
      </AuthContext>
    </BrowserRouter>
  </StrictMode>
);
