import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import "./ProfileDetails.css";
import { PhotoData } from "../Photo/PhotoData";

const ProfileDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  if (!state) {
    navigate("/");
    return null;
  }

  const { name } = state;

  const photographerImages = PhotoData.filter(
    (photo) => photo.namePhotographer === name
  );

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="profile-details-page2">
      <div className="profile-detailss">
        {photographerImages.length > 0 && (
          <div className="photo-gallery2">
            <h3>
              Artworks by <span>{name}</span>
            </h3>
            <div className="gallery-grid2">
              {photographerImages.map((photo) => (
                <div key={photo.id} className="gallery-item2">
                  <img
                    src={photo.image}
                    alt={photo.name}
                    className="gallery-image2"
                    onClick={() => openModal(photo)}
                  />
                  <div className="gallery-overlay2">
                    <h4>{photo.name}</h4>
                    <p>{photo.size} cm</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div onClick={closeModal}>
          {isModalOpen && selectedImage && (
            <div className="modal-overlay2">
              <div
                className="modal-content2"
                onClick={(e) => e.stopPropagation()}
              >
                <img
                  src={selectedImage.image}
                  alt={selectedImage.name}
                  className="modal-image2"
                />
                <div className="modal-info2">
                  <h4>{selectedImage.name}</h4>
                  <div className="modal-actions2">
                    <Link
                      to={`/GazaPhoto/${selectedImage.id}`}
                      className="details-btn2"
                    >
                      View Details
                    </Link>
                    <a
                      href={selectedImage.StripLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="buy-btn2"
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
                <button className="close-button2" onClick={closeModal}>
                  ×
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
