import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import Web3 from "web3";
import { hostURL } from "../services/data_requests";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    JSON.parse(localStorage.getItem("isAuthenticated")) || false
  );
  const [userEmail, setUserEmail] = useState(
    localStorage.getItem("userEmail") || null
  );
  const [ethAddress, setEthAddress] = useState(
    localStorage.getItem("ethAddress") || null
  );

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${hostURL}user`, {
          withCredentials: true,
        });
        if (response.data) {
          const { eth_account_address, email } = response.data;
          setIsAuthenticated(true);
          setUserEmail(email);
          setEthAddress(eth_account_address);

          localStorage.setItem("isAuthenticated", JSON.stringify(true));
          localStorage.setItem("userEmail", email);
          if (eth_account_address) {
            localStorage.setItem("ethAddress", eth_account_address);
          }
        } else {
          logout();
        }
      } catch (error) {
        logout();
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
    localStorage.setItem("userEmail", userEmail);
    localStorage.setItem("ethAddress", ethAddress);
  }, [isAuthenticated, userEmail, ethAddress]);

  const loginWithEmail = (email) => {
    setIsAuthenticated(true);
    setUserEmail(email);
  };

  const authenticateWeb3 = async (account) => {
    const message = "Please sign this message to authenticate.";
    const web3 = new Web3(window.ethereum);

    try {
      const signature = await web3.eth.personal.sign(message, account, "");
      const authResponse = await axios.post(
        `${hostURL}web3_auth`,
        { address: account, message: message, signature: signature },
        { withCredentials: true }
      );

      if (authResponse.status === 204) {
        setIsAuthenticated(true);
        setEthAddress(account);
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      console.error("Web3 authentication failed:", error);
      return { success: false };
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserEmail(null);
    setEthAddress(null);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userEmail,
        ethAddress,
        loginWithEmail,
        authenticateWeb3,
        logout,
        setEthAddress,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
