import React from "react";
import { Link } from "react-router-dom";
import "./MarketCard.scss";
import {
  artistURL,
  previewURL,
  formatName,
  useToGetItemData,
  useToGetString,
  toTitleCase,
} from "../../../services";

const CardWrapper = ({ link, children, image }) => {
  const cardImage = {
    backgroundImage: `url(${image})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="ArtworkCard">
      <Link to={link} className="art-card-link">
        <div className="art-card">
          <div className="art-card-image" style={cardImage}></div>
          <div className="art-card-content">{children}</div>
        </div>
      </Link>
    </div>
  );
};

const ArtworkCard = ({ type, data, full }) => {
  const route = "artist";

  return (
    <CardWrapper
      link={`/${type}/${formatName(data.name)}-${data.id}`}
      image={data.prev_img_url}
    >
      <div className="card-context">
        <div className="art-card-title">{toTitleCase(data.name)}</div>
        {data?.artist && (
          <div className="art-card-auth">{toTitleCase(data.artist.name)}</div>
        )}
      </div>
    </CardWrapper>
  );
};

const ArtistCard = ({ data }) => {
  return (
    <CardWrapper
      link={`/artist/${formatName(data.name)}-${data.id}`}
      image={data.img_url}
    >
      <div className="card-context">
        <div className="art-card-title">{toTitleCase(data.name)}</div>
      </div>
    </CardWrapper>
  );
};
export { ArtworkCard, ArtistCard };
