import React, { useEffect, useState } from "react";
import "./User.scss";
import { hostURL, useToGetUserData } from "../../services";
import { Purchases, UserData } from "../../components";
import RequestCard from "../../components/Features/RequestCard/RequestCard";

const User = () => {
  const userInfo = useToGetUserData();
  const [nfts, setNfts] = useState([]);
  const [artworks, setArtworks] = useState([]);
  const [requests, setRequests] = useState([]);
  const [profileStatus, setProfileStatus] = useState(false);
  const [loadingNfts, setLoadingNfts] = useState(true);
  const [loadingArtworks, setLoadingArtworks] = useState(true);
  const [loadingProfileStatus, setLoadingProfileStatus] = useState(true);

  const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  useEffect(() => {
    let currentIndex = 0;

    if (artworks.length > 0) {
      const slides = document.querySelectorAll(".slide");
      if (slides.length > 0) slides[0].classList.add("active");
    }

    const interval = setInterval(() => {
      const slides = document.querySelectorAll(".slide");
      if (slides.length > 0) {
        slides.forEach((slide, index) => {
          slide.classList.remove("active");
          if (index === currentIndex) {
            slide.classList.add("active");
          }
        });
        currentIndex = (currentIndex + 1) % slides.length;
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [artworks]);

  useEffect(() => {
    const fetchNfts = async () => {
      try {
        const response = await fetch(`${hostURL}user/nft`, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          setNfts(data);
        }
      } catch (error) {
        console.error("Error fetching NFTs:", error);
      } finally {
        setLoadingNfts(false);
      }
    };

    const fetchArtworks = async () => {
      try {
        const response = await fetch(`${hostURL}artwork`, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          const limitedData = data.slice(0, 10);
          setArtworks(limitedData);
          preloadImages(limitedData.map((artwork) => artwork.prev_img_url));
        }
      } catch (error) {
        console.error("Error fetching artworks:", error);
      } finally {
        setLoadingArtworks(false);
      }
    };

    const checkCreatorStatus = async () => {
      try {
        const response = await fetch(`${hostURL}LOA/is_creator`, {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          setProfileStatus(data.status);
        }
      } catch (error) {
        console.error("Error fetching creator status:", error);
      } finally {
        setLoadingProfileStatus(false);
      }
    };

    Promise.all([fetchNfts(), fetchArtworks(), checkCreatorStatus()]);
  }, []);

  useEffect(() => {
    const fetchCreatorRequests = async () => {
      try {
        const response = await fetch(`${hostURL}LOA/creator_requests`, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          setRequests(data);
        }
      } catch (error) {
        console.error("Error fetching creator requests:", error);
      }
    };

    if (profileStatus) {
      fetchCreatorRequests();
    }
  }, [profileStatus]);

  if (!userInfo || !userInfo.username) {
    return (
      <div className="auth-prompt">
        <p className="promt-mes">
          You are not logged in. Please <a href="/auth">log in</a> to continue.
        </p>
      </div>
    );
  }

  const handleRequestDelete = (deletedId) => {
    setRequests((prevRequests) =>
      prevRequests.filter((request) => request.id !== deletedId)
    );
  };

  return (
    <div className="user-content">
      <div className="user-greet">
        {loadingArtworks ? (
          <div>Loading Artworks...</div>
        ) : (
          <div className="slideshow">
            {artworks.map((artwork, index) => (
              <div key={index} className="slide">
                <img
                  src={artwork.prev_img_url}
                  alt={`Artwork ${index + 1}`}
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {loadingProfileStatus ? (
        <div>Loading Profile...</div>
      ) : (
        <UserData data={userInfo} creatorStatus={profileStatus} />
      )}

      {profileStatus && requests.length > 0 && (
        <ul className="request-history">
          {requests.map((item) => (
            <li key={item.id} className="request-placeholder">
              <RequestCard data={item} onDelete={handleRequestDelete} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default User;
