import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { imgg14, imgg20, imgg7 } from "../../../assets";
import { Link } from "react-router-dom";
import "./GallerySection.css";

const PreviewCard = React.memo(({ name, image, namePhotographer }) => (
  <div className="preview-card">
    <img src={image} alt={name} className="preview-image" />
    <div className="preview-details">
      <h3 className="preview-name">{name}</h3>
      <p className="preview-desc">{namePhotographer}</p>
    </div>
  </div>
));

const GallerySection = () => {
  const previewProfiles = [
    {
      name: "Wide open",
      image: imgg7,
      namePhotographer: "Fadi Badwan",
    },
    {
      name: "Collective Echoes",
      image: imgg14,
      namePhotographer: "Jehad Al Shrafi",
    },
    {
      name: "Yet Again",
      image: imgg20,
      namePhotographer: "Ismael Abu Dayyah",
    },
  ];

  return (
    <div className="GallerySection-page">
      <section className="photographers-preview">
        <h2 className="preview-title">Funooni Exhibition</h2>
        <Swiper
          modules={[Navigation]}
          navigation={{
            nextEl: ".swiper-button-next-preview",
            prevEl: ".swiper-button-prev-preview",
          }}
          spaceBetween={10}
          slidesPerView={3}
          breakpoints={{
            0: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          allowTouchMove={false}
          className="preview-swiper"
        >
          {previewProfiles.map((profile, index) => (
            <SwiperSlide key={index}>
              <PreviewCard
                name={profile.name}
                image={profile.image}
                namePhotographer={profile.namePhotographer}
              />
            </SwiperSlide>
          ))}
          <div className="swiper-button-prev-preview">
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          <div className="swiper-button-next-preview">
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </Swiper>
        <Link to="/GazaPhoto" className="explore-button">
          Explore All
        </Link>
      </section>
    </div>
  );
};

export default GallerySection;
