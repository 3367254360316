import React from "react";
import "./style.scss";

const AboutSection = ({ title, description, image }) => {
  return (
    <div className="About-container">
      <div className="About">
        <div className="About-content">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </div>
      <div className="About-image">
        <img src={image} alt="About section" />
      </div>
    </div>
  );
};

export default AboutSection;
