import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import "./ContactForm.scss";

const ContactForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    emailjs.init("bqCognPp3dwI-zn31");
  }, []);

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value.trimStart();
    setter(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: `${firstName} ${lastName}`,
      message: message,
      reply_to: email,
    };

    console.log("Sending email with params:", templateParams);

    emailjs
      .send(
        "service_eh8bsc8",
        "template_3qpqve7",
        templateParams,
        "bqCognPp3dwI-zn31"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Message sent successfully!");
          setFirstName("");
          setLastName("");
          setEmail("");
          setMessage("");
        },
        (err) => {
          console.error("FAILED...", err);
          alert(
            "Failed to send message: " + (err.text || "Please try again later.")
          );
        }
      )
      .catch((err) => {
        console.error("Unexpected error:", err);
        alert(
          "An unexpected error occurred. Please check your connection and try again."
        );
      });
  };

  return (
    <div className="contact-form">
      <div className="contact-form-title">
        <h2>Contact us</h2>
      </div>
      <div className="forms">
        <form onSubmit={handleSubmit}>
          <div className="contact-input">
            <label>First name*</label>
            <input
              type="text"
              value={firstName}
              onChange={handleInputChange(setFirstName)}
              required
              placeholder="Enter your first name"
            />
          </div>
          <div className="contact-input">
            <label>Last name</label>
            <input
              type="text"
              value={lastName}
              onChange={handleInputChange(setLastName)}
              placeholder="Enter your last name"
            />
          </div>
          <div className="contact-input">
            <label>Email*</label>
            <input
              type="email"
              value={email}
              onChange={handleInputChange(setEmail)}
              required
              placeholder="Enter your email"
            />
          </div>
          <div className="contact-input">
            <label>What can we help you with?</label>
            <textarea
              value={message}
              onChange={handleInputChange(setMessage)}
              placeholder="Type your message here"
              rows="5"
            />
          </div>
          <button type="submit">
            <p>Submit</p>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
