import React, { useState } from "react";
import "./NewsSection.css";
import { Fadi, haitham, ismael, Jehad, Mahmoud } from "../../../assets";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
const ProfileCard = React.memo(
  ({
    name,
    description,
    image,
    detailedImage,
    fullDescription,
    onOpenModal,
  }) => {
    const [isHovered, setIsHovered] = React.useState(false);

    return (
      <div className="profile-container">
        <div
          className="profile-card"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <img src={image} alt={name} className="profile-image" />
          <div className="hover-details">
            <div className={`name-container ${isHovered ? "hovered" : ""}`}>
              {name}
            </div>
            {isHovered && (
              <div className="details-content">
                <p>{description}</p>
                <button
                  className="c-btn c-btn-news inverse"
                  onClick={() =>
                    onOpenModal({ name, detailedImage, fullDescription })
                  }
                >
                  <span>READ MORE</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

const NewsSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleOpenModal = (data) => {
    setModalData(data);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const profiles = [
    {
      name: "Fadi Badwan",
      description:
        "Fadi Badwan, a photographer from Gaza, captures every angle of people's suffering and the beauty of the land through his lens. Specializing in landscape photography, ",
      fullDescription:
        "Fadi Badwan, a photographer from Gaza, captures every angle of people's suffering and the beauty of the land through his lens. Specializing in landscape photography, he showcases the beauty of Gaza from both its bright and dark sides, documenting stories of life and hope in every moment. He works with many local and international institutions and participates in numerous international and local exhibitions.",
      image: Fadi,
      detailedImage: Fadi,
    },
    {
      name: "Jehad Al Shrafi",
      description:
        "At 23 years old, Jehad Al Shrafi is an independent photojournalist and humanitarian from Gaza, Palestine. He dedicates his work to documenting humanitarian events and the impact of the war on Gaza,",
      fullDescription:
        "At 23 years old, Jehad Al Shrafi is an independent photojournalist and humanitarian from Gaza, Palestine. He dedicates his work to documenting humanitarian events and the impact of the war on Gaza, aiming to amplify the voices of the victims and raise global awareness of their issues. He collaborates with international agencies and newspapers, contributing to humanitarian and artistic projects to make a positive impact. Holding a Bachelor’s degree in Management and Economics with a specialization in Digital Marketing, Jehad strives to highlight the issue of war in Gaza, believing in the power of photography to spread humanitarian messages and inspire change. ",
      image: Jehad,
      detailedImage: Jehad,
    },
    {
      name: "Mahmoud Abo Hamda",
      description:
        "Mahmoud Abu Hamda is a photographer and filmmaker based in Gaza City. He specializes in storytelling through documentary photography and emotionally rich visuals.",
      fullDescription:
        "Mahmoud Abu Hamda is a photographer and filmmaker based in Gaza City. He specializes in storytelling through documentary photography and emotionally rich visuals. His work focuses on capturing human experiences and authentic moments, blending artistic creativity with realism. Mahmoud has participated in exhibitions and projects that highlight his unique vision. In addition to his artistic work, Mahmoud conducts workshops on photography and filmmaking for young people in his community. He is passionate about shedding light on humanitarian and social issues through visual arts.",
      image: Mahmoud,
      detailedImage: Mahmoud,
    },
    {
      name: "Ismael Abu Dayyah",
      description:
        "Ismael Abu Dayyah is a freelance photojournalist from Gaza City, in the Occupied Palestinian Territories. Abu Dayyah is a dedicated visual storyteller,",
      fullDescription:
        "Ismael Abu Dayyah is a freelance photojournalist from Gaza City, in the Occupied Palestinian Territories. Abu Dayyah is a dedicated visual storyteller, with a primary aim to document the stories and daily realities of life under occupation in Gaza. Since 2018, and continuously since October 2023, he has reported on the ongoing siege of the Gaza Strip. Abu Dayyah has worked with a number of media and humanitarian agencies, including The Associated Press, Al Arabiya, and UN OCHA, and has published works on top news sites such as The Guardian, POLITICO, and The New York Times.",
      image: ismael,
      detailedImage: ismael,
    },
    {
      name: "Haitham Nour Al-Deen",
      description:
        "Haitham Nour Al-Deen, 30 years old, was born in Palestine (Gaza Strip). He works as a photojournalist for a European agency, ",
      fullDescription:
        "Haitham Nour Al-Deen, 30 years old, was born in Palestine (Gaza Strip). He works as a photojournalist for a European agency, specializing in photographing conflicts, wars, and daily life. He has covered many wars in his country and has received numerous international and local awards. He is currently in the Gaza Strip covering the ongoing war",
      image: haitham,
      detailedImage: haitham,
    },
  ];

  return (
    <div className="application">
      <Swiper
        modules={[Navigation]}
        navigation={{
          nextEl: ".swiper-button-next-custom",
          prevEl: ".swiper-button-prev-custom",
        }}
        spaceBetween={10}
        slidesPerView={3}
        breakpoints={{
          0: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
        loop={true}
        className="profile-swiper"
      >
        {profiles.map((profile, index) => (
          <SwiperSlide key={index}>
            <ProfileCard
              name={profile.name}
              description={profile.description}
              image={profile.image}
              detailedImage={profile.detailedImage}
              fullDescription={profile.fullDescription}
              onOpenModal={handleOpenModal}
            />
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev-custom">
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <div className="swiper-button-next-custom">
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </Swiper>

      {isModalOpen && modalData && (
        <div className="modal-overlayy" onClick={handleCloseModal}>
          <div className="modal-contentt" onClick={(e) => e.stopPropagation()}>
            <div className="detailed-view">
              <div className="detailed-image-container slide-from-left">
                <img
                  src={modalData.detailedImage}
                  alt={`${modalData.name} detailed`}
                  className="detailed-image"
                />
              </div>
              <div className="detailed-text slide-from-right">
                <div className="detail-text">
                  <h2>{modalData.name}</h2>
                  <p>{modalData.fullDescription}</p>
                  <button
                    className="c-btn c-btn-news inverse-dark"
                    onClick={handleCloseModal}
                  >
                    <span>CLOSE</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsSection;
