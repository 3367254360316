import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { Link } from "react-router-dom";
import "./Slider2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { formatName, hostURL, toTitleCase } from "../../../../services";

const Slider2 = () => {
  const [artworkData, setArtworkData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const artworkResponse = await axios.get(
          `${hostURL}artwork?count=10&offset=0`
        );
        setArtworkData(artworkResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    appendDots: (dots) => (
      <div style={{ backgroundColor: "#000", padding: "10px 0" }}>
        <ul
          style={{
            margin: "0",
            padding: "0",
            justifyContent: "center",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className="custom-dot"
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
        }}
      ></div>
    ),
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {artworkData.map((artwork, index) => (
          <div className="slider-item" key={artwork.id}>
            {artwork.prev_img_url && artwork.name ? (
              <div
                className="slider-image"
                style={{
                  backgroundImage: `url(${artwork.prev_img_url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  height: "42vh",
                  width: "100%",
                }}
              >
                <div className="slider_content">
                  <div className="content">
                    <div
                      className="artist-ava"
                      style={{
                        backgroundImage: `url(${artwork.prev_img_url})`,
                      }}
                    ></div>
                    <h3>{toTitleCase(artwork.name)}</h3>
                    <p>
                      By {toTitleCase(artwork.artist?.name || "Unknown Artist")}
                    </p>
                  </div>
                  <Link
                    to={`/artwork/${formatName(artwork.name)}-${artwork.id}`}
                    className="view-collection-button"
                  >
                    View Collection
                  </Link>
                </div>
              </div>
            ) : (
              <div>No artwork available</div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Slider2;
