import React from "react";
import FilterColumn from "../FilterColumn/FilterColumn";

const FilterComponent = ({
  filters,
  selectedFilters,
  onFilterChange,
  onClearFilters,
  title,
}) => {
  const handleClearFilters = () => {
    const resetFilters = Object.keys(filters).reduce((acc, filterType) => {
      acc[filterType] = "all";
      return acc;
    }, {});
    onClearFilters(resetFilters);
  };

  return (
    <div className="filter-container">
      <div className="flex_filter">
        <div className="market-type-header">
          <h1>{title}</h1>
        </div>
        <a
          href="#"
          className="clear-settings"
          onClick={(e) => {
            e.preventDefault();
            handleClearFilters();
          }}
        >
          Clear Settings
        </a>
      </div>
      <div className="filter-content">
        {Object.entries(filters).map(([filterType, items]) => {
          if (Array.isArray(items)) {
            return (
              <FilterColumn
                key={filterType}
                title={filterType.replace(/_/g, " ").toUpperCase()}
                items={items}
                selectedFilter={selectedFilters[filterType]}
                onFilterChange={onFilterChange}
                filterType={filterType}
              />
            );
          } else if (typeof items === "object" && items !== null) {
            const mergedItems = Object.entries(items).map(
              ([subGroup, subItems]) => ({
                group: subGroup.toUpperCase(),
                items: subItems,
              })
            );

            return (
              <FilterColumn
                key={filterType}
                title={filterType.replace(/_/g, " ").toUpperCase()}
                items={mergedItems}
                selectedFilter={selectedFilters[filterType]}
                onFilterChange={onFilterChange}
                filterType={filterType}
              />
            );
          } else {
            return (
              <div key={filterType}>Invalid filter format for {filterType}</div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default FilterComponent;
