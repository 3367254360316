import React, { useState, useEffect } from "react";
import "./SinglePageField.scss";

export const SinglePageField = ({ spf_title, children, ...attr }) => {
  const extraFields = React.Children.toArray(children).filter(
    (child) => child.props.place === "extraField"
  );

  const contentFields = React.Children.toArray(children).filter(
    (child) => child.props.place === "content"
  );

  return (
    <div className="SinglePageField">
      <div className="spf-container">
        <div className="spf-title">
          <div className="spf-main-title">{spf_title}</div>
          <div className="spf-extra-field">{extraFields}</div>
        </div>
        <div className="spf-content">{contentFields}</div>
      </div>
    </div>
  );
};

export const MultiPageField = ({ children, options }) => {
  const [activeOption, setActiveOption] = useState(null);

  useEffect(() => {
    if (options.length > 0) {
      setActiveOption(options[0]);
    }
  }, [options]);

  const handleTabClick = (option) => {
    setActiveOption(option);
  };

  const filteredOptions = options.filter(
    (option) => option && option.trim() !== ""
  );

  return (
    <div className="SinglePageField">
      <div className="spf-container">
        {filteredOptions.length > 0 && (
          <div className="mpf-title">
            {filteredOptions.map((option) => (
              <div
                key={option}
                className={`mpf-main-title ${
                  activeOption === option ? "active" : ""
                }`}
                onClick={() => handleTabClick(option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}

        <div className="spf-content">
          {React.Children.map(children, (child) => {
            if (child && child.props) {
              return child.props.option === activeOption ? child : null;
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};
