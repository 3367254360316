import React, { useState, useEffect } from "react";
import axios from "axios";
import { useToGetData, hostURL } from "../../services";
import {
  ArtworkCard,
  ArtistCard,
  Preloader,
  Slider2,
  FilterComponent,
} from "../../components";
import "./MarketPage.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";

const MarketPage = ({ marketType }) => {
  const [filters, setFilters] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    categories: "All",
    medium: "All",
    price: "All",
    collection: "All",
    country: "All",
  });
  const [marketData, setMarketData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [offset, setOffset] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isPrevLoading, setIsPrevLoading] = useState(false);
  const limit = 24;

  const { blockItems, loading: fetchLoading, totalCount } = useToGetData(
    marketType,
    limit,
    offset,
    1000,
    selectedFilters
  );

  const totalPages = Math.ceil(totalCount / limit);
  console.log("totalCount:", totalCount);
  console.log("totalPages:", totalPages);

  const fetchFilters = async () => {
    try {
      const endpoint =
        marketType === "artwork"
          ? `${hostURL}artwork/filter_data`
          : marketType === "nfts"
          ? `${hostURL}nft/filter_data`
          : `${hostURL}artist/filter_data`;

      const response = await axios.get(endpoint);
      const filterKeyMap = {
        collections: "collection",
        countries: "country",
      };

      const normalizeFilterKeys = (filters) => {
        const normalizedFilters = {};
        Object.entries(filters).forEach(([key, value]) => {
          const normalizedKey = filterKeyMap[key] || key;
          normalizedFilters[normalizedKey] = value;
        });
        return normalizedFilters;
      };

      const normalizedFilters = normalizeFilterKeys(response.data);
      const fetchedFilters = {
        ...normalizedFilters,
        ...(marketType !== "artist" && {
          price: ["Under 1K USD", "Under 5K USD", "Under 10K USD"],
        }),
      };
      setFilters(fetchedFilters);
      setIsDataLoaded(true);
    } catch (error) {
      console.error("Error fetching filters:", error);
      setLoading(false);
    }
  };

  const fetchData = async (newOffset) => {
    if (fetchLoading) {
      console.log("Fetch is already in progress, skipping...");
      return;
    }
    setLoading(true);
    try {
      if (blockItems && blockItems.length >= 0) {
        let updatedItems = [...blockItems];
        if (searchQuery.trim()) {
          updatedItems = updatedItems.filter((item) =>
            item.name.toLowerCase().includes(searchQuery.trim().toLowerCase())
          );
        }
        setMarketData(updatedItems);
        setOffset(newOffset);
        const hasMore = newOffset + limit < totalCount;
        setHasMoreData(hasMore);
      } else {
        setMarketData([]);
        setHasMoreData(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setHasMoreData(false);
    } finally {
      setLoading(false);
      setIsNextLoading(false);
      setIsPrevLoading(false);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, [marketType]);

  useEffect(() => {
    if (isDataLoaded) {
      fetchData(0);
    }
  }, [isDataLoaded]);

  useEffect(() => {
    if (blockItems !== undefined) {
      fetchData(offset);
    }
  }, [blockItems, searchQuery, selectedFilters]);

  const handleFilterChange = (filterType, value) => {
    setSelectedFilters((prev) => {
      const updatedFilters = { ...prev, [filterType]: value };
      if (filterType === "Print" && value !== "all") {
        updatedFilters.Painting = value;
        updatedFilters.Print = "All";
      } else if (filterType === "price") {
        updatedFilters.price = value;
      }
      if (filterType === "categories") {
        updatedFilters.categories = value;
      }
      if (filterType === "Painting") {
        updatedFilters.medium = value;
      }
      setOffset(0);
      setMarketData([]);
      return updatedFilters;
    });
  };

  const handleClearFilters = () => {
    setSelectedFilters({
      categories: "All",
      medium: "All",
      price: "All",
      collection: "All",
      country: "All",
    });
    setSearchQuery("");
    setOffset(0);
    setMarketData([]);
  };

  const handleNextPage = () => {
    if (hasMoreData && !loading) {
      setIsNextLoading(true);
      const newOffset = offset + limit;
      setOffset(newOffset);
    }
  };

  const handlePreviousPage = () => {
    if (offset >= limit && !loading) {
      setIsPrevLoading(true);
      const newOffset = offset - limit;
      setOffset(newOffset);
    }
  };

  const handlePageChange = (pageNumber) => {
    if (!loading) {
      const newOffset = (pageNumber - 1) * limit;
      setOffset(newOffset);
    }
  };

  const currentPage = Math.floor(offset / limit) + 1;

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxDisplay = 4;

    if (totalPages <= maxDisplay) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pageNumbers.push(1, 2, 3);
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else if (currentPage > totalPages - 3) {
        pageNumbers.push(1);
        pageNumbers.push("...");
        pageNumbers.push(totalPages - 2, totalPages - 1, totalPages);
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");
        pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
        if (currentPage + 1 < totalPages - 1) {
          pageNumbers.push("...");
        }
        pageNumbers.push(totalPages);
      }
    }

    const filteredNumbers = pageNumbers.filter((num) => typeof num === "number");
    if (filteredNumbers.length > maxDisplay) {
      const excess = filteredNumbers.length - maxDisplay;
      filteredNumbers.splice(1, excess); 
      pageNumbers.length = 0;
      pageNumbers.push(...filteredNumbers, "...", totalPages);
    }

    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();
  console.log("Displayed pageNumbers:", pageNumbers);

  const renderCard = (data, index) => {
    if (data) {
      if (marketType === "nfts") {
        return <ArtworkCard type="nft" data={data} key={`nft-${index}`} full />;
      } else if (marketType === "artist") {
        return <ArtistCard data={data} key={`artist-${index}`} />;
      } else if (marketType === "artwork") {
        return (
          <ArtworkCard
            type={marketType}
            data={data}
            key={`art-${index}`}
            full
          />
        );
      }
    }
  };

  useEffect(() => {
    setSelectedFilters({
      categories: "All",
      medium: "All",
      price: "All",
      collection: "All",
      country: "All",
    });
    setMarketData([]);
    setOffset(0);
    setHasMoreData(true);
    setSearchQuery("");
    setIsDataLoaded(false);
    setFilters(null);
  }, [marketType]);

  return (
    <div className="MarketPage">
      {isDataLoaded && filters ? (
        <>
          <div className="marketCover">
            <Slider2 />
          </div>
          <div className="filters-container">
            <FilterComponent
              filters={filters}
              selectedFilters={selectedFilters}
              onFilterChange={handleFilterChange}
              onClearFilters={handleClearFilters}
              title={marketType.toUpperCase()}
            />
          </div>
          <div className="search-container">
            <i className="fa fa-search" aria-hidden="true"></i>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input"
            />
          </div>

          <div className="art-card-container">
            {marketData.length > 0 ? (
              marketData.map((data, index) => renderCard(data, index))
            ) : loading ? (
              <div className="no-items">Loading...</div>
            ) : (
              <div className="no-items">
                No items found for the selected filters.
              </div>
            )}
          </div>

          <div className="market-pagination">
            <button
              className="market-page-button market-page-button--prev"
              onClick={handlePreviousPage}
              disabled={offset === 0 || loading}
            >
              {isPrevLoading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                "Previous"
              )}
            </button>

            <div className="market-page-numbers">
              {pageNumbers.length > 0 ? (
                pageNumbers.map((page, index) =>
                  page === "..." ? (
                    <span key={`ellipsis-${index}`} className="ellipsis">
                      ...
                    </span>
                  ) : (
                    <button
                      key={page}
                      className={`market-page-button market-page-button--number ${
                        currentPage === page ? "active" : ""
                      }`}
                      onClick={() => handlePageChange(page)}
                      disabled={loading}
                    >
                      {page}
                    </button>
                  )
                )
              ) : (
                <span className="no-pages">No pages to display</span>
              )}
            </div>

            <button
              className="market-page-button market-page-button--next"
              onClick={handleNextPage}
              disabled={!hasMoreData || loading}
            >
              {isNextLoading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                "Next"
              )}
            </button>
          </div>
        </>
      ) : (
        <Preloader />
      )}
      {loading && <div className="loading-spinner">Loading...</div>}
    </div>
  );
};

export default MarketPage;