import React, { useEffect, useState } from "react";
import "./Artist.scss";
import {
  ArtworkCard,
  SinglePageField,
  MultiPageField,
} from "../../../components";
import { toTitleCase, useToGetCollection } from "../../../services";

const Artist = ({ data }) => {
  const [artistItem, setArtistItem] = useState({});
  const [collectionArray, setCollectionArray] = useState([]);

  const itemArray = useToGetCollection("artist/work", data.id);

  useEffect(() => {
    if (data) {
      setArtistItem(data);
    }
  }, [data]);

  useEffect(() => {
    if (itemArray) {
      setCollectionArray(itemArray);
    }
  }, [itemArray]);

  const profileAva = {
    backgroundImage: `url(${artistItem.img_url})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const renderCards = (dataArray, type) => {
    if (dataArray && dataArray.length > 0) {
      return dataArray.map((data, index) => (
        <li className="list-item" key={`${type}-${index}`}>
          <ArtworkCard type={type} data={data} full />
        </li>
      ));
    }
    return <p>No artwork found</p>;
  };

  const options = [];
  if (collectionArray?.artworks?.length > 0) options.push("Artworks");
  if (collectionArray?.nfts?.length > 0) options.push("NFT");

  const totalItems =
    (collectionArray?.artworks?.length || 0) +
    (collectionArray?.nfts?.length || 0);

  const shouldRenderMultiPageField = options.length > 0;

  let headerImage = "";
  if (collectionArray?.artworks?.length > 0) {
    headerImage =
      collectionArray.artworks[collectionArray.artworks.length - 1]
        .prev_img_url;
  } else if (collectionArray?.nfts?.length > 0) {
    headerImage =
      collectionArray.nfts[collectionArray.nfts.length - 1].prev_img_url;
  }

  if (!headerImage) {
    headerImage = "#cccccc";
  }

  return (
    <div className="artist-container">
      <div className="artist-header">
        <div
          className="artist-header-image"
          style={{
            backgroundImage: `url(${headerImage})`,
            backgroundColor: `${headerImage}`,
          }}
        ></div>
        <div className="artist-header-content">
          <div className="artist-header-container">
            <div className="artist-header-avatar">
              <div className="avatar-inner" style={profileAva}></div>
            </div>
            <div>
              <div className="artist-header-name">
                {toTitleCase(artistItem.name)}
              </div>
              <div className="artist-collection-info">
                Collections: {totalItems} posted
              </div>
            </div>
          </div>
          <div className="artist-tegs">
            <div className="tegs-title">Artistic Trajectory</div>
            {artistItem.categories
              ? artistItem.categories.map((item, index) => (
                  <div key={index} className="artist-teg">
                    #{item}
                  </div>
                ))
              : null}
          </div>
          <div className="artist-extra-field"></div>
        </div>
      </div>
      <SinglePageField spf_title="BIO">
        <div place="content">{artistItem.bio}</div>
      </SinglePageField>

      {shouldRenderMultiPageField && (
        <MultiPageField options={options}>
          {options.includes("Artworks") && (
            <div className="children_opt1" option="Artworks">
              <div place="content">
                <ul className="artist-work-list">
                  {renderCards(collectionArray.artworks, "artwork")}
                </ul>
              </div>
            </div>
          )}
          {options.includes("NFT") && (
            <div className="children_opt2" option="NFT">
              <div place="content">
                <ul className="artist-work-list">
                  {renderCards(collectionArray.nfts, "nft")}
                </ul>
              </div>
            </div>
          )}
        </MultiPageField>
      )}
    </div>
  );
};

export default Artist;
