// OurServices.js
import React from "react";
import "./OurService.scss";

const OurServices = () => {
  return (
    <div className="services-section">
      <div className="services-tit">
        <h2 className="services-title">Our Services</h2>
      </div>
      <button className="learn-more-btn">Learn More About Us</button>
    </div>
  );
};

export default OurServices;
