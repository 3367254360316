import React from "react";
import "./FunooniNFTs.scss";

const Step = ({ image, title, content, link, list }) => {
  return (
    <div className="step">
      <img src={image} alt={title} />
      <div className="step_content">
        <h3>{title}</h3>
        {content && <p>{content}</p>}
        {list && (
          <ul>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
        {link && (
          <a href={link.href} target="_blank" rel="noopener noreferrer">
            {link.text}
          </a>
        )}
      </div>
    </div>
  );
};

export default Step;
