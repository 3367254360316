import React, { useState, useEffect } from "react";
import "./AuthenticitySection.scss";
import { horse } from "../../../assets";
import { Link } from "react-router-dom";
import { hostURL, useToGetUserData } from "../../../services";

const AuthenticitySection = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(true);

  const userInfo = useToGetUserData();

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const checkCreatorStatus = async () => {
      if (userInfo && userInfo.username) {
        try {
          const response = await fetch(`${hostURL}LOA/is_creator`, {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
            credentials: "include",
          });

          if (response.ok) {
            const data = await response.json();
            console.log("Creator status data:", data);
            setIsCreator(data.status || false);
          } else {
            console.log(
              "Failed to fetch creator status, status:",
              response.status
            );
            setIsCreator(false);
          }
        } catch (error) {
          console.error("Error checking creator status:", error);
          setIsCreator(false);
        }
      } else {
        setIsCreator(false);
      }
      setLoadingStatus(false);
    };

    checkCreatorStatus();
  }, [userInfo]);

  const getButtonConfig = () => {
    console.log("Current states:", { userInfo, isCreator, loadingStatus });

    if (loadingStatus) {
      return {
        text: "Loading...",
        link: "#",
        disabled: true,
      };
    }
    if (!userInfo || !userInfo.username) {
      return {
        text: "Log in to continue",
        link: "/auth",
      };
    } else if (!isCreator) {
      return {
        text: "Upgrade Profile Now",
        link: "https://buy.stripe.com/fZe4idfGM4OSbKMg2m",
      };
    } else {
      return {
        text: "Verify my artwork",
        link: "/profile",
      };
    }
  };

  const buttonConfig = getButtonConfig();

  return (
    <section className="authenticity-section">
      <div className={`authenticity-content ${isExpanded ? "expanded" : ""}`}>
        <div className="authenticity-container">
          <div className="image-container">
            <img
              src={horse}
              alt="Colorful Horse Artwork"
              className="horse-image"
            />
          </div>
          <div className="text-container">
            <h2>Securing Art Authenticity with Funooni COA</h2>
            <p>
              At Funooni, we ensure the authenticity of your physical artwork
              through blockchain technology. Each physical artwork now comes
              with a QR certificate linked to a digital authenticity token,
              providing verifiable proof of ownership and provenance. This
              Certificate of Authenticity (COA) seamlessly merges the physical
              and digital worlds, enhancing the collector's experience while
              securing the integrity of your artwork.
            </p>
          </div>
          <div className="pricing-container">
            <div className="price">$90/month</div>
            <ul className="features-list">
              <li className="span">
                <span className="check-mark">✔</span>{" "}
                <span>
                  <strong>Verifiable Ownership: </strong> Immutable blockchain
                  records secure authentication.
                </span>
              </li>
              <li className="span">
                <span className="check-mark">✔</span>{" "}
                <span>
                  <strong>Phygital Experience:</strong> A combined physical and
                  digital certification.
                </span>
              </li>
              <li className="span">
                <span className="check-mark">✔</span>{" "}
                <span>
                  <strong>Enhanced Security:</strong> NFTs prevent tampering and
                  ensure long-term validity.
                </span>
              </li>
            </ul>
            <Link
              to={buttonConfig.link}
              className={`upgrade-button ${
                buttonConfig.disabled ? "disabled" : ""
              }`}
              onClick={(e) => buttonConfig.disabled && e.preventDefault()}
            >
              {buttonConfig.text}
            </Link>
          </div>
        </div>
        <div className="how">
          {isExpanded && (
            <>
              <div className="expanded-content">
                <div className="left-authenticity">
                  <h3>How It Works</h3>
                  <ul className="how-it-works">
                    <li>
                      <strong>Blockchain-Linked Authentication:</strong> Each
                      COA is minted as an ERC721 token, making it tamper-proof
                      and permanently verifiable.
                    </li>
                    <li>
                      <strong>Metadata-Embedded Digital Art:</strong> Digital
                      artworks are linked to their COA through embedded
                      metadata, securing their authenticity.
                    </li>
                    <li>
                      <strong>QR Code Integration:</strong> Physical artworks
                      include a scannable QR code, allowing instant verification
                      via blockchain records.
                    </li>
                  </ul>
                  <p>
                    This innovative approach ensures transparency and trust for
                    both collectors and artists, bridging the gap between
                    physical and digital art experiences.
                  </p>
                </div>
                <div className="right-authenticity">
                  <h3>Funooni COA Setup Process</h3>
                  <ol className="setup-process">
                    <li>
                      <strong>Sign up</strong> – Create an account on Funooni
                      platform to access COA issuance tools.
                    </li>
                    <li>
                      <strong>Upgrade to a Creator subscription Plan</strong> –
                      Subscribe to unlock unlimited COA issuance for both
                      digital and physical artworks. Your plan covers the
                      minting fees for Ethereum-based ERC721 NFTs.
                    </li>
                    <li>
                      <strong>Upload Your Artwork</strong> – Submit your digital
                      or physical artwork details, including title, creator
                      name, dimensions, and medium.
                    </li>
                    <li>
                      <strong>Verification</strong> – Funooni reviews your
                      submission to confirm authenticity before issuing a
                      blockchain-backed Certificate of Authenticity (COA).
                    </li>
                    <li>
                      <strong>Certificate & QR Code Issuance</strong> – Once
                      verified, your COA is minted on the blockchain. Digital
                      art is linked via metadata, while physical artworks
                      receive a unique QR code for instant verification.
                    </li>
                  </ol>
                </div>
              </div>
              <a
                href="#"
                className="read-more"
                onClick={(e) => {
                  e.preventDefault();
                  toggleExpand();
                }}
              >
                Hide
              </a>
            </>
          )}
        </div>
      </div>
      {!isExpanded && (
        <a
          href="#"
          className="read-more"
          onClick={(e) => {
            e.preventDefault();
            toggleExpand();
          }}
        >
          Read more
        </a>
      )}
    </section>
  );
};

export default AuthenticitySection;
