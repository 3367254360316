import React from "react";
import "./UserData.scss";
import { Link } from "react-router-dom";

const LoaRequest = ({ creatorStatus, ...attr }) => {
  const statusStyles = {
    creator: {
      color: "#00ff2f",
      fontWeight: 600,
    },
    standard: {
      color: "#ff7700",
      fontWeight: 600,
    },
  };

  return (
    <div className="loa-interface">
      <p>
        Profile status:{" "}
        <span style={creatorStatus ? statusStyles.creator : statusStyles.standard}>
          {creatorStatus ? "Creator" : "Standard"}
        </span>
      </p>
      {creatorStatus ? (
        <div className="creator-requests">
          <Link to="../verify-profile">
            <div className="loa-nav-btn">Verify artist profile</div>
          </Link>
          <Link to="../certificate-tool">
            <div className="loa-nav-btn">Verify artwork</div>
          </Link>
        </div>
      ) : (
        <Link to="https://buy.stripe.com/fZe4idfGM4OSbKMg2m">
          <div className="loa-nav-btn">Upgrade profile</div>
        </Link>
      )}
    </div>
  );
};

export default LoaRequest;
