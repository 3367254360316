import React, { useState, useEffect, useRef } from "react";
import "./Modal.scss";

const Modal = ({ isOpen, onClose, artItem, source_item }) => {
  const mediaRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const isVideo = artItem.is_animated;

  useEffect(() => {
    const adjustDimensions = () => {
      if (mediaRef.current) {
        const media = mediaRef.current;
        const aspectRatio = media.video
          ? media.video.videoWidth / media.video.videoHeight
          : media.naturalWidth / media.naturalHeight;

        const containerHeight = media.parentElement.clientHeight;
        const containerWidth = containerHeight * aspectRatio;

        media.style.height = `${containerHeight}px`;
        media.style.width = `${containerWidth}px`;
      }
    };
    if (isLoaded) {
      adjustDimensions();
      window.addEventListener("resize", adjustDimensions);
    }

    return () => {
      window.removeEventListener("resize", adjustDimensions);
    };
  }, [isLoaded]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {isVideo ? (
          <video
            ref={mediaRef}
            autoPlay
            loop
            preload="none"
            className="modal-media"
            onLoadedData={() => setIsLoaded(true)}
          >
            <source src={source_item} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            ref={mediaRef}
            src={source_item}
            alt="Artwork"
            className="modal-media"
            onLoad={() => setIsLoaded(true)}
          />
        )}
        <div className="modal-close" onClick={onClose}>
          Close
        </div>
      </div>
    </div>
  );
};

export default Modal;
