import React, { useState } from "react";
import PropTypes from "prop-types";

const FilterColumn = ({
  title,
  items,
  onFilterChange,
  filterType,
  selectedFilter,
}) => {
  const [currentValue, setCurrentValue] = useState(selectedFilter || "all");

  const handleChange = (e) => {
    const value = e.target.value;
    setCurrentValue(value);
    onFilterChange(filterType, value);
  };

  React.useEffect(() => {
    setCurrentValue(selectedFilter || "all");
  }, [selectedFilter]);

  return (
    <div className="dropdown">
      <select
        className="dropdown-select"
        value={currentValue}
        onChange={handleChange}
      >
        <option value="all" hidden>{` ${title}`}</option>
        {Array.isArray(items) &&
          items.map((itemGroup, index) => {
            if (itemGroup.group && Array.isArray(itemGroup.items)) {
              return (
                <React.Fragment key={index}>
                  <option value="" disabled>
                    {`-- ${itemGroup.group} --`}
                  </option>
                  {itemGroup.items.map((item, idx) => (
                    <option key={`${itemGroup.group}-${idx}`} value={item}>
                      {item}
                    </option>
                  ))}
                </React.Fragment>
              );
            } else {
              return (
                <option key={index} value={itemGroup}>
                  {itemGroup}
                </option>
              );
            }
          })}
      </select>
    </div>
  );
};

FilterColumn.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
  onFilterChange: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default FilterColumn;
