import React, { useEffect, useState } from "react";
import "./UserData.scss";
import { hostURL } from "../../../../services/data_requests";
import { FaPen } from "react-icons/fa";
import { toast } from "react-toastify";
import LoaRequest from "./LoaRequest";

const UserData = ({ data, creatorStatus, ...attr }) => {
  const [walletStatus, setWalletStatus] = useState(
    data.eth_account_address ? "connected" : "not connected"
  );
  const [showPopup, setShowPopup] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [code, setCode] = useState("");
  const [username, setUsername] = useState(data.username || "");
  const [isEditing, setIsEditing] = useState(false);
  const [walletAddress, setWalletAddress] = useState(
    data.eth_account_address || ""
  );
  const [isEmailConnected, setIsEmailConnected] = useState(!!data.email);
  const [isWalletConnected, setIsWalletConnected] = useState(
    !!data.eth_account_address
  );
  const [emailStatus, setEmailStatus] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(data.subscribe_to_news);

  const handleSubscriptionChange = async (event) => {
    const newStatus = event.target.checked;

    if (!isEmailConnected) {
      toast.error(
        "Please connect your email with your user profile to be subscribed.",
        {
          autoClose: 5000,
        }
      );
      setIsSubscribed(false);
      return;
    }

    const previousStatus = isSubscribed;
    setIsSubscribed(newStatus);

    try {
      const response = await fetch(`${hostURL}user/subscribe_to_news`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ isSubscribed: newStatus }),
      });

      if (response.ok) {
        console.log("Subscription status updated successfully");
      } else {
        setIsSubscribed(previousStatus);
        alert("Failed to update subscription. Please try again.");
      }
    } catch (error) {
      console.error("Error updating subscription:", error);
      setIsSubscribed(previousStatus);
      alert("An unexpected error occurred. Please try again.");
    }
  };
  const resetPopupState = () => {
    setEmail("");
    setCode("");
    setEmailError("");
    setEmailStatus("");
    setStatusMessage("");
  };

  const handleEdit = () => setIsEditing(true);

  const handleSave = async () => {
    if (!username) {
      alert("Username cannot be empty.");
      return;
    }

    try {
      const response = await fetch(
        `${hostURL}user/username?username=${encodeURIComponent(username)}`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );

      if (response.ok) {
        const result = await response.json();
        alert("Username updated successfully!");
        setIsEditing(false);
      } else {
        const errorData = await response.json();
        alert(errorData.message || "Failed to update username.");
      }
    } catch (error) {
      console.error("Error updating username:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  const handleCancel = () => setIsEditing(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const connectWallet = async () => {
    if (!window.ethereum) {
      alert("MetaMask is not installed!");
      return;
    }

    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const walletAddress = accounts[0];

      const response = await fetch(`${hostURL}web3_auth/get_email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ address: walletAddress }),
      });

      const result = await response.json();

      if (response.ok) {
        if (result.email) {
          setEmail(result.email);
          setIsEmailConnected(true);
          alert("Wallet successfully connected with email!");
          window.location.href = "/profile";
        } else {
          setIsEmailConnected(false);
          alert(
            "No email is associated with this wallet. Please connect an email."
          );
          setShowPopup(true);
        }
        setWalletStatus("connected");
        setWalletAddress(walletAddress);
      } else {
        alert("Failed to connect wallet. Please try again.");
      }
    } catch (error) {
      console.error("Wallet connection error:", error);
      alert("Failed to connect wallet. Ensure MetaMask is unlocked.");
    }
  };

  const handleCheckCode = async () => {
    if (!code) {
      setStatusMessage({ type: "error", text: "Code is required" });
      return;
    }

    if (!email) {
      setStatusMessage({ type: "error", text: "Email is required" });
      return;
    }

    try {
      const response = await fetch(
        `${hostURL}user/check_code?code=${encodeURIComponent(
          code
        )}&user_email=${encodeURIComponent(email)}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );

      const result = await response.json();
      if (response.ok) {
        setStatusMessage({
          type: "success",
          text: "Email successfully linked!",
        });
      } else {
        const errorMessage =
          result.message ||
          result.detail ||
          "Failed to verify code. Please try again.";
        setStatusMessage({ type: "error", text: errorMessage });
      }
      window.location.reload();
    } catch (error) {
      console.error("Code verification error:", error);
      setStatusMessage({
        type: "error",
        text: "An error occurred. Please try again.",
      });
    }
  };

  const handleEmailSubmit = async () => {
    if (!email) {
      setStatusMessage({ type: "error", text: "Please enter your email." });
      return;
    }

    if (!validateEmail(email)) {
      setStatusMessage({
        type: "error",
        text: "Please enter a valid email address.",
      });
      return;
    }

    try {
      const response = await fetch(
        `${hostURL}user/email?email=${encodeURIComponent(email)}`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );

      const result = await response.json();
      if (response.ok) {
        setShowPopup(false);
        setShowConfirmationPopup(true);
        setStatusMessage({
          type: "success",
          text: "Confirmation code sent to your email.",
        });
      } else {
        const errorMessage =
          result.message ||
          result.detail ||
          "Failed to send email. Please try again.";
        setStatusMessage({ type: "error", text: errorMessage });
      }
    } catch (error) {
      console.error("Email confirmation error:", error);
      setStatusMessage({
        type: "error",
        text: "An error occurred. Please try again.",
      });
    }
  };

  const [statusMessage, setStatusMessage] = useState(null);

  return (
    <div className="userdata-block">
      <div className="userdata-container">
        <div className="userdata-content">
          <div className="user-base-info">
            <div className="data-pack">
              <div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                    {isEditing ? (
                      <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        style={{
                          padding: "5px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          fontSize: "14px",
                        }}
                      />
                    ) : (
                      username
                    )}
                  </span>
                  {!isEditing && (
                    <button
                      onClick={handleEdit}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <FaPen />
                    </button>
                  )}
                </div>
                {isEditing && (
                  <div
                    style={{ marginTop: "10px", display: "flex", gap: "10px" }}
                  >
                    <button
                      onClick={handleSave}
                      style={{
                        padding: "5px 10px",
                        background: "#28a745",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancel}
                      style={{
                        padding: "5px 10px",
                        background: "#dc3545",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
              <div>
                <p>Email:</p>
                {isEmailConnected ? (
                  <span className="email">{data.email}</span>
                ) : (
                  <button
                    className="connect-button"
                    onClick={() => setShowPopup(true)}
                  >
                    Connect Email
                  </button>
                )}
              </div>
              <div>
                <p>MetaMask Wallet:</p>
                {isWalletConnected ? (
                  <span className="wallet-address2">{walletAddress}</span>
                ) : (
                  <button className="connect-button" onClick={connectWallet}>
                    Connect
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="close-div">
              <button
                className="close-button"
                onClick={() => {
                  resetPopupState();
                  setShowPopup(false);
                }}
              >
                ×
              </button>
            </div>
            <p>
              If you want to link an email to your account, enter your address,
              after which a confirmation code will be sent to your email.
            </p>
            <input
              className="e-mail"
              type="email"
              placeholder="e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {statusMessage && (
              <p
                className={
                  statusMessage.type === "error"
                    ? "error-message"
                    : "status-message"
                }
              >
                {statusMessage.text}
              </p>
            )}
            <button className="submit-button" onClick={handleEmailSubmit}>
              Connect
            </button>
          </div>
        </div>
      )}

      {showConfirmationPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="close-div">
              <button
                className="close-button"
                onClick={() => {
                  resetPopupState();
                  setShowConfirmationPopup(false);
                }}
              >
                ×
              </button>
            </div>
            <p>
              Enter your confirmation code here to link your email to your
              account.
            </p>
            <input
              className="confirmation-code"
              type="text"
              placeholder="confirmation code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />

            {statusMessage && (
              <p
                className={
                  statusMessage.type === "error"
                    ? "error-message"
                    : "status-message"
                }
              >
                {statusMessage.text}
              </p>
            )}
            <div className="buttons2">
              <button
                className="confirmation-connect-wallet"
                onClick={handleEmailSubmit}
              >
                Resend Code
              </button>
              <button
                className="confirmation-Confirm"
                onClick={handleCheckCode}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="bottom-section">
        <div className="notifications">
          <h4>E-mail notifications</h4>
          <div className="subscribed_content">
            <p
              className={`status ${
                isSubscribed ? "subscribed" : "unsubscribed"
              }`}
            >
              {isSubscribed ? "Subscribed" : "Unsubscribed"}
            </p>
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={isSubscribed}
                onChange={handleSubscriptionChange}
              />
              <span className="slider"></span>
            </label>
          </div>
        </div>
        <LoaRequest creatorStatus={creatorStatus}/>
      </div>
    </div>
  );
};

export default UserData;
