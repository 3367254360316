import React, { useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Environment } from "@react-three/drei";
import Model from "./Model";
import "./FashionTool.scss";
import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb,
} from "react-circular-input";
import { hostURL } from "../../services";
import { Blending_and_Color, Desert } from "../../assets";

const ColorInput = ({ htmlFor, label, color, setColor }) => {
  return (
    <div className="color-input-group">
      <label htmlFor={htmlFor}>{label}</label>
      <div className="color-display">
        <input
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
        <span className="color-code">{color}</span>
      </div>
    </div>
  );
};

const FashionTool = () => {
  const [backgroundColor, setBackgroundColor] = useState("#aaaaaa");
  const [shirtColor, setShirtColor] = useState("#000000");
  const [interfaceMode, setInterfaceMode] = useState("Decal");
  const [decalMode, setDecalMode] = useState("Front");
  const [decalUVOffset, setDecalUVOffset] = useState({ u: 0, v: 0 });
  const [decalScale, setDecalScale] = useState(0.15);
  const [decalRotation, setDecalRotation] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const [baseColor, setBaseColor] = useState("#D9D9D9");
  const [decalImage, setDecalImage] = useState(Desert);
  const [activeType, setActiveType] = useState("");
  const [activeSize, setActiveSize] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [orderNotes, setOrderNotes] = useState("");
  const [fashionArtworks, setFashionArtworks] = useState([]);
  const [activeArtwork, setActiveArtwork] = useState(null);
  const [artistInfo, setArtistInfo] = useState(null);

  useEffect(() => {
    fetch(`${hostURL}fashion`)
      .then((response) => response.json())
      .then((data) => {
        setFashionArtworks(data);
        if (data.length > 0) {
          setActiveArtwork(data[0]);
          fetchArtistInfo(data[0].artist_id);
        }
      })
      .catch((error) =>
        console.error("Error fetching fashion artworks:", error)
      );
  }, []);

  const fetchArtistInfo = (artistId) => {
    fetch(`${artistId}artist/${artistId}`)
      .then((response) => response.json())
      .then((data) => setArtistInfo(data))
      .catch((error) => console.error("Error fetching artist info:", error));
  };

  const handleArtworkClick = (artwork, index) => {
    setActiveArtwork(artwork);
    const decals = [Desert, Blending_and_Color];
    setDecalImage(decals[index]);
    fetchArtistInfo(artwork.artist_id);
  };

  const handleTypeClick = (type) => {
    setActiveType(type);
  };

  const handleSizeClick = (size) => {
    setActiveSize(size);
  };

  const handleImportConfig = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const importedConfig = JSON.parse(e.target.result);

        setBackgroundColor(importedConfig.backgroundColor);
        setShirtColor(importedConfig.shirtColor);
        setInterfaceMode(importedConfig.interfaceMode);
        setDecalMode(importedConfig.decalMode);
        setDecalUVOffset(importedConfig.decalUVOffset);
        setDecalScale(importedConfig.decalScale);
        setDecalRotation(importedConfig.decalRotation);
        setBaseColor(importedConfig.baseColor);
        setDecalImage(importedConfig.decalImage);
        setActiveType(importedConfig.activeType);
        setActiveSize(importedConfig.activeSize);

        if (importedConfig.customerInfo) {
          setWalletAddress(importedConfig.customerInfo.walletAddress);
          setEmail(importedConfig.customerInfo.email);
        }

        if (importedConfig.shippingAddress) {
          setFirstName(importedConfig.shippingAddress.firstName);
          setLastName(importedConfig.shippingAddress.lastName);
          setCountry(importedConfig.shippingAddress.country);
          setCity(importedConfig.shippingAddress.city);
          setStreet(importedConfig.shippingAddress.street);
          setPostalCode(importedConfig.shippingAddress.postalCode);
          setFullAddress(importedConfig.shippingAddress.fullAddress);
          setOrderNotes(importedConfig.shippingAddress.orderNotes);
        }
      } catch (error) {
        console.error("File uploading Error:", error);
      }
    };

    reader.readAsText(file);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setDecalImage(imageUrl);
    } else {
      setDecalImage(null);
    }
  };

  const handleDecalUVOffsetChange = (axis, value) => {
    setDecalUVOffset((prev) => ({
      ...prev,
      [axis]: parseFloat(value),
    }));
  };

  const handleDecalScaleChange = (value) => {
    setDecalScale(parseFloat(value));
  };

  const handleExportConfig = () => {
    const config = {
      backgroundColor,
      shirtColor,
      interfaceMode,
      decalMode,
      decalUVOffset,
      decalScale,
      decalRotation,
      baseColor,
      decalImage,
      activeType,
      activeSize,
      customerInfo: {
        walletAddress,
        email,
        type: activeType,
        size: activeSize,
      },
      shippingAddress: {
        firstName,
        lastName,
        country,
        city,
        street,
        postalCode,
        fullAddress,
        orderNotes,
      },
    };

    const jsonString = JSON.stringify(config, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "fashion_tool_config.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="fashion-tool-container">
      <div className="canvas-container" style={{ backgroundColor }}>
        <Canvas shadows camera={{ position: [1, 1, 2], fov: 20 }}>
          <ambientLight intensity={0.1} />
          <directionalLight
            position={[5, 5, 5]}
            intensity={0.3}
            castShadow
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
          />
          <Model
            supportColor={shirtColor}
            BaseColor={baseColor}
            decalUVOffset={decalUVOffset}
            decalScale={decalScale}
            decalRotation={decalRotation}
            decalImage={decalImage}
          />
          <OrbitControls />
          <Environment preset="studio" />
        </Canvas>
      </div>
      <div className={`settings-panel ${isPanelOpen ? "open" : "closed"}`}>
        {isOpen && (
          <div className="">
            <div className="input-group">
              <label>Demo Version</label>
              <label>Print</label>
              <div className="print-options">
                {fashionArtworks.map((artwork, index) => (
                  <div
                    key={artwork.id}
                    className={`print-view ${
                      activeArtwork?.id === artwork.id ? "selected" : ""
                    }`}
                    style={{ backgroundImage: `url(${artwork.prev_img_url})` }}
                    onClick={() => handleArtworkClick(artwork, index)}
                  >
                    {activeArtwork?.id === artwork.id && (
                      <div className="selected-option"></div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="form-group print-details">
              <div className="sprint-troke">
                <label className="print-label">Name</label>
                <div>{activeArtwork?.name}</div>
              </div>
              <div className="sprint-troke">
                <label className="print-label">Collection</label>
                <div>{activeArtwork?.collection}</div>
              </div>
              <div className="sprint-troke">
                <label className="print-label">Artist</label>
                <div>{"Salah Shaheen"}</div>
              </div>
            </div>
            <label>Type</label>
            <select id="dropdown">
              <option value="1">T-Shirt</option>
              {/* <option value="2">Hoodie</option> // will be available soon keep it*/}

              {/* <option value="3">Bag</option> // will be available soon keep it */}
            </select>
            <p>Settings</p>
            <div className="input-group">
              <ColorInput
                label="Background Color:"
                color={backgroundColor}
                setColor={setBackgroundColor}
                htmlFor="background-color"
              />
            </div>
            <div className="input-group">
              <ColorInput
                label="Shirt Color:"
                color={shirtColor}
                setColor={setShirtColor}
                htmlFor="shirt-color"
              />
            </div>
            <div className="input-group">
              <ColorInput
                label="Base Color:"
                color={baseColor}
                setColor={setBaseColor}
                htmlFor="second-material-colo"
              />
            </div>
            <div className="input-group">
              <label className="margin">Mode</label>
              <div className="mode-buttons margin">
                {activeArtwork?.available_mods.map((mod) => (
                  <button
                    key={mod}
                    onClick={() => setInterfaceMode(mod)}
                    className={interfaceMode === mod ? "active" : ""}
                  >
                    {mod}
                  </button>
                ))}
              </div>
              {interfaceMode === "Decal" && (
                <div className="interface-mode-content ">
                  <div>
                    <label>Position</label>
                    <div className="mode-buttons margin">
                      {/* <button
                        onClick={() => setDecalMode("Both")}
                        className={decalMode === "Both" ? "active" : "Both"}
                      >
                        Both
                      </button> */}
                      <button
                        onClick={() => setDecalMode("Front")}
                        className={decalMode === "Front" ? "active" : "Front"}
                      >
                        Front
                      </button>
                      {/* <button
                        onClick={() => setDecalMode("Back")}
                        className={decalMode === "Back" ? "active" : "Back"}
                      >
                        Back
                      </button> */}
                    </div>
                  </div>

                  {decalMode === "Both" && <>Both</>}
                  {decalMode === "Front" && (
                    <>
                      <div className="input-group">
                        <label htmlFor="decal-offset-u">Decal Offset U:</label>
                        <input
                          className="fashion-slider"
                          type="range"
                          id="decal-offset-u"
                          min="-0.5"
                          max="0.5"
                          step="0.01"
                          value={decalUVOffset.u}
                          onChange={(e) =>
                            handleDecalUVOffsetChange("u", e.target.value)
                          }
                        />

                        <span className="center">
                          {decalUVOffset.u.toFixed(2)}
                        </span>
                      </div>
                      <div className="input-group">
                        <label htmlFor="decal-offset-v">Decal Offset V:</label>
                        <input
                          type="range"
                          className="fashion-slider"
                          id="decal-offset-v"
                          min="-0.5"
                          max="0.5"
                          step="0.01"
                          value={decalUVOffset.v}
                          onChange={(e) =>
                            handleDecalUVOffsetChange("v", e.target.value)
                          }
                        />
                        <span className="center">
                          {decalUVOffset.v.toFixed(2)}
                        </span>
                      </div>
                      <div className="input-group">
                        <label htmlFor="decal-scale">Decal Scale:</label>
                        <input
                          type="range"
                          className="fashion-slider"
                          id="decal-scale"
                          min="0.1"
                          max="0.2"
                          step="0.01"
                          value={decalScale}
                          onChange={(e) =>
                            handleDecalScaleChange(e.target.value)
                          }
                        />
                        <span className="center">{decalScale.toFixed(2)}</span>
                      </div>
                      <div className="input-group">
                        <label htmlFor="texture-rotation">
                          Decal Rotation:
                        </label>
                        <CircularInput
                          value={decalRotation / (2 * Math.PI)}
                          onChange={(v) => setDecalRotation(v * 2 * Math.PI)}
                        >
                          <CircularTrack
                            style={{ stroke: "gray", strokeWidth: 5 }}
                          />
                          <CircularProgress
                            style={{ stroke: "black", strokeWidth: 5 }}
                          />
                          <CircularThumb r={10} style={{ fill: "black" }} />
                        </CircularInput>

                        <span>
                          {(decalRotation * (180 / Math.PI)).toFixed(1)}°
                        </span>
                      </div>
                    </>
                  )}
                  {decalMode === "Back" && <>Back</>}
                </div>
              )}
              {interfaceMode === "Alpha" && (
                <div className="interface-mode-content">
                  Alpha Interface - available soon
                </div>
              )}
              {interfaceMode === "Pattern" && (
                <div className="interface-mode-content">
                  Pattern Interface - available soon
                </div>
              )}
            </div>
            <div className="customer-form">
              <label className="title">Customer Information</label>

              <div className="form-group">
                <label className="label">Wallet Address</label>
                <input
                  type="text"
                  className="input"
                  value={walletAddress}
                  onChange={(e) => setWalletAddress(e.target.value)}
                  placeholder="0x..."
                  required
                />
              </div>

              <div className="form-group">
                <label className="label">E-mail</label>
                <input
                  type="email"
                  className="input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="example@example.com"
                  required
                />
              </div>

              <div className="form-group">
                <label className="label">Type</label>
                <div className="button-group">
                  {["Male", "Female"].map((type) => (
                    <button
                      key={type}
                      className={`button ${
                        activeType === type ? "active" : ""
                      }`}
                      onClick={() => handleTypeClick(type)}
                    >
                      {type}
                    </button>
                  ))}
                </div>
              </div>

              <div className="form-group">
                <label className="label">Size</label>
                <div className="button-group size-buttons">
                  {["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"].map(
                    (size) => (
                      <button
                        key={size}
                        className={`button ${
                          activeSize === size ? "active" : ""
                        }`}
                        onClick={() => handleSizeClick(size)}
                      >
                        {size}
                      </button>
                    )
                  )}
                </div>
              </div>

              <div className="form-group">
                <label className="title">Shipping Address</label>
                <div className="address-group">
                  <div className="form-group">
                    <label className="label">First Name</label>
                    <input
                      type="text"
                      className="input"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label className="label">Last Name</label>
                    <input
                      type="text"
                      className="input"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label className="label">Country</label>
                    <input
                      type="text"
                      className="input"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      placeholder="Country"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label className="label">City</label>
                    <input
                      type="text"
                      className="input"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="City"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label className="label">Street</label>
                    <input
                      type="text"
                      className="input"
                      value={street}
                      onChange={(e) => setStreet(e.target.value)}
                      placeholder="Street"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label className="label">Postal Code</label>
                    <input
                      type="text"
                      className="input"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      placeholder="Postal Code"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label className="label">Full Address</label>
                    <input
                      type="text"
                      className="input"
                      value={fullAddress}
                      onChange={(e) => setFullAddress(e.target.value)}
                      placeholder="Unit number, house number, building"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label className="label">Order Notes</label>
                    <textarea
                      className="input"
                      value={orderNotes}
                      onChange={(e) => setOrderNotes(e.target.value)}
                      placeholder="Notes"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="export-button-container">
                <button onClick={handleExportConfig} className="export-button">
                  Export Page Template
                </button>
                <input
                  type="file"
                  accept="application/json"
                  onChange={handleImportConfig}
                  style={{ display: "none" }}
                  id="import-config"
                />
                <div
                  onClick={() =>
                    document.getElementById("import-config").click()
                  }
                  className="import-button"
                >
                  <p>Import Page Template</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={`toggle-button ${isPanelOpen ? "moved" : ""}`}
        onClick={() => setIsPanelOpen(!isPanelOpen)}
      >
        <div className="toggle-b"></div>
      </div>
    </div>
  );
};

export default FashionTool;