import React, { useState, useEffect, useRef } from "react";
import "./Art.scss";
import { formatName, toTitleCase, useToFindItem } from "../../../services";
import { Link } from "react-router-dom";
import { Modal, SinglePageField } from "../../../components";

const PhyArt = ({ data }) => {
  const [artItem, setArtItem] = useState({});
  const [shareText, setShareText] = useState("Share");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const previewRef = useRef(null);

  const ownerData = useToFindItem("artist", data?.artist?.id);

  useEffect(() => {
    if (data) {
      setArtItem(data);
    }
  }, [data]);

  const handleShare = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setShareText("Copied");
        setTimeout(() => setShareText("Share"), 3000);
      })
      .catch((err) => console.error("Failed to copy the link: ", err));
  };

  return (
    <div className="art-single-page">
      <div className="ars-container">
        <div className="ars-preview-container">
          <div
            className="ars-preview"
            ref={previewRef}
            style={{
              backgroundImage: `url(${artItem.prev_img_url})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
            onClick={() => setIsModalOpen(true)}
          ></div>
        </div>
        <div className="ars-buffer"></div>
        <div className="ars-info">
          <div className="ars-title">
            <div className="art-title-name">{artItem.name}</div>
            <div className="share-btn" onClick={handleShare}>
              {shareText}
            </div>
          </div>
          <div className="ars-title-under">
            <div className="ars-author">
              Artist: {toTitleCase(artItem.artist?.name) || "Unknown Artist"}
            </div>
            <div className="ars-rarity">{artItem.size}</div>
          </div>
          {artItem.stripe_link ? (
            <a className="stripe_link" href={artItem.stripe_link}>
              <div className="purchase_btn">
                <a className="stripe_link">Own it now - {artItem.price} $</a>
              </div>
            </a>
          ) : (
            <div className="purchase_btn">
              <p>Will be available soon</p>
            </div>
          )}
        </div>
      </div>
      <SinglePageField spf_title="About the work">
        <div place="content">
          <div className="static-information">
            <ul className="ars-net">
              <li className="ars-field">
                <div className="ars-inf-field">Name</div>
                <div className="ars-inf-subfield">
                  {toTitleCase(artItem.name)}
                </div>
              </li>
              <li className="ars-field">
                <div className="ars-inf-field">Collection</div>
                <div className="ars-inf-subfield">
                  {toTitleCase(artItem.collection)}
                </div>
              </li>
              <li className="ars-field">
                <div className="ars-inf-field">Size</div>
                <div className="ars-inf-subfield">{artItem.size}</div>
              </li>
              <li className="ars-field">
                <div className="ars-inf-field">Medium</div>
                <div className="ars-inf-subfield">{artItem.medium}</div>
              </li>
              <li className="ars-field">
                <div className="ars-inf-field">Categories</div>
                <div className="ars-inf-subfield">
                  <ul>
                    {artItem.categories &&
                      artItem.categories?.map((item, index) => (
                        <li key={index}>#{item}</li>
                      ))}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="description-field">
            <div className="description-container">
              <div className="ars-inf-field">Description</div>
              <div className="sector-content ars-inf-subfield">
                {artItem.description}
              </div>
            </div>
          </div>
        </div>
      </SinglePageField>
      <SinglePageField spf_title="About the author">
        <div className="linker" place="extraField">
          {ownerData ? (
            <Link to={`/artist/${formatName(ownerData.name)}-${ownerData.id}`}>
              <div>{toTitleCase(ownerData.name)}</div>
              <div
                className="artst-ava"
                style={{
                  backgroundImage: `url(${ownerData.img_url})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Link>
          ) : (
            <div>Loading author data...</div>
          )}
        </div>
        <div place="content">
          {ownerData ? (
            <div className="ars-full-bio">{ownerData.bio}</div>
          ) : (
            <div>Loading biography...</div>
          )}
        </div>
      </SinglePageField>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        artItem={artItem}
        source_item={artItem.img_url || null}
      />
    </div>
  );
};

export default PhyArt;
