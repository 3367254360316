import React, { useState, useEffect } from "react";
import Web3 from "web3";
import axios from "axios";
import { toast } from "react-toastify";

const ConnectWalletModal = ({ isOpen, onClose, onConnect }) => {
  const [account, setAccount] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (isOpen) {
      // Check if the user is already logged in
      axios
        .get(
          "https://nftbazar-backend-main-104732163593.europe-west9.run.app/user",
          { withCredentials: true }
        )
        .then((response) => {
          setUserData(response.data);
        })
        .catch(() => {
          setUserData(null);
        });
    }
  }, [isOpen]);

  const connectMetaMask = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);
        onConnect(accounts[0]);
      } catch (error) {
        toast.error("MetaMask connection failed");
      }
    } else {
      toast.error("MetaMask is not installed");
    }
  };

  const authorizeUser = async () => {
    if (userData && userData.eth_account_address !== account) {
      // If the wallet is not connected to the user's profile, we need to set the address
      const web3 = new Web3(window.ethereum);
      const message = `Please sign this message to authorize your wallet: ${userData.email}`;
      try {
        const signature = await web3.eth.personal.sign(message, account, "");
        await axios.post(
          "https://nftbazar-backend-main-104732163593.europe-west9.run.app/web3_auth/set_address",
          {
            address: account,
            message,
            signature,
          }
        );
        toast.success("Wallet authorized");
        onClose(); // Close the modal after successful authorization
      } catch (error) {
        toast.error("Authorization failed");
      }
    } else {
      toast.success("Wallet is already authorized");
      onClose(); // Close the modal if the wallet is already authorized
    }
  };

  return (
    isOpen && (
      <div className="connect-wallet-modal">
        <div className="modal-content">
          <h3>Connect Your Wallet</h3>
          {!userData && (
            <button onClick={connectMetaMask}>Connect MetaMask</button>
          )}
          {userData && (
            <>
              <p>{`Hello, ${userData.username}`}</p>
              <button onClick={authorizeUser}>Authorize Wallet</button>
            </>
          )}
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    )
  );
};

export default ConnectWalletModal;
