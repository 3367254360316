import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const HomeSection = ({ title, description, button1, button2, image }) => {
  return (
    <div className="home-container">
      <div className="home-content">
        <h1>{title}</h1>
        <p>{description}</p>
        <div className="button-group">
          <Link className="button-explore" to={"/artworks"}>
            {button1}
          </Link>
          <Link className="span-about" to={"/about"}>
            {button2}
          </Link>
        </div>
      </div>
      <div className="home-image">
        <img src={image} alt="Home section" />
      </div>
    </div>
  );
};

export default HomeSection;
