import React from "react";
import { ArtworksCarousel, Main } from "../../components";
import { GallerySection } from "../../components/Galary";

const Home = () => {
  return (
    <div className="home">
      <div>
        <Main />
        <ArtworksCarousel title="Etching" medium="etching" />
        <ArtworksCarousel title="Oil on Canvas" medium="oil on canvas" />
        <ArtworksCarousel title="Painting" categories="painting" />
        <ArtworksCarousel title="Print" categories="print" />

        <ArtworksCarousel title="Sculpture" categories="sculpture" />
        <GallerySection />
      </div>
    </div>
  );
};

export default Home;
