import React from "react";
import "./Photo.css";
import { PhotoData } from "./PhotoData";
import { Link } from "react-router-dom";

const Photo = () => {
  const uniquePhotographers = [
    ...new Map(
      PhotoData.map((item) => [
        item.namePhotographer,
        {
          name: item.namePhotographer,
          image: item.image,
        },
      ])
    ).values(),
  ];

  return (
    <section className="testimonials">
      <div className="gallery-container2">
        <h2 className="gallery-title">Photographers Collection</h2>
        <div className="photographers-grid">
          {uniquePhotographers.map((photographer, index) => (
            <div key={index} className="photographer-item">
              <img
                src={photographer.image}
                alt={photographer.name}
                className="photographer-image"
                loading="lazy" 
              />
              <div className="photographer-info">
                <p className="photographer-name">{photographer.name}</p>
                <Link
                  to={{
                    pathname: `/profile/${photographer.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}`,
                  }}
                  state={photographer}
                  className="explore-button"
                >
                  Explore
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Photo;
