import React, { useRef, useState } from "react";
import "./CertificateTool.scss";
import { FaPen } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CertificateTool = () => {
  const [formData, setFormData] = useState({
    artworkName: "",
    artistName: "",
    collection: "",
    rarity: "1/1",
    tokensToMint: "",
    type: "Digital art",
    medium: "",
    categories: ["Digital Art"],
    description: "",
    price: 0,
    currency: "USD",
    image: null,
  });

  const [categoryInput, setCategoryInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const API_BASE_URL = "https://api.funooni.com";
  const [editingField, setEditingField] = useState(null);
  const [showCategoryInput, setShowCategoryInput] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRarityChange = (e) => {
    const rarityValue = e.target.value;
    setFormData({
      ...formData,
      rarity: rarityValue,
      tokensToMint: rarityValue === "1/1" ? "" : formData.tokensToMint,
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 32 * 1024 * 1024) {
        toast.error("File size exceeds 32 MB limit.");
        return;
      }
      setFormData({ ...formData, image: file });
    }
  };

  const handleAddCategory = () => {
    if (categoryInput && !formData.categories.includes(categoryInput)) {
      setFormData({
        ...formData,
        categories: [...formData.categories, categoryInput],
      });
      setCategoryInput("");
      setShowCategoryInput(false);
    }
  };

  const handleRemoveCategory = (categoryToRemove) => {
    setFormData({
      ...formData,
      categories: formData.categories.filter((cat) => cat !== categoryToRemove),
    });
  };

  const validateForm = () => {
    const requiredFields = [
      "artworkName",
      "artistName",
      "collection",
      "rarity",
      "type",
      "medium",
      "description",
      "price",
    ];
    for (const field of requiredFields) {
      if (!formData[field]) {
        toast.error(`Field "${field}" is required.`);
        return false;
      }
    }
    if (formData.categories.length === 0) {
      toast.error("At least one category is required.");
      return false;
    }
    if (!formData.image) {
      toast.error("Image is required.");
      return false;
    }
    if (
      (formData.rarity === "Open Edition" || formData.rarity === "n/n") &&
      !formData.tokensToMint
    ) {
      toast.error("Please specify the number of tokens to mint.");
      return false;
    }
    return true;
  };

  const checkCreatorStatus = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/LOA/is_creator`, {
        method: "GET",
        credentials: "include",
      });
      if (!response.ok) throw new Error("Failed to verify creator status");
      const data = await response.json();
      return data.status === true;
    } catch (err) {
      toast.error("Error verifying creator status: " + err.message);
      return false;
    }
  };

  const createTextRecord = async () => {
    const rarityString =
      formData.rarity === "1/1"
        ? "1/1"
        : `Type of rarity: ${formData.rarity} - tokens to mint: ${formData.tokensToMint}`;
    const requestBody = {
      type: formData.type,
      content: [
        {
          artworkName: formData.artworkName,
          artistName: formData.artistName,
          collection: formData.collection,
          rarity: rarityString,
          medium: formData.medium,
          categories: formData.categories,
          description: formData.description,
          price: formData.price,
          currency: formData.currency,
        },
      ],
    };

    try {
      const response = await fetch(`${API_BASE_URL}/LOA/request`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) throw new Error("Failed to create text record");
      const data = await response.json();
      return data.loa_id;
    } catch (err) {
      toast.error("Error creating text record: " + err.message);
      return null;
    }
  };

  const uploadImage = async (file, recordId) => {
    const formDataToSend = new FormData();
    formDataToSend.append("file", file);
    formDataToSend.append("recordId", recordId);

    try {
      const response = await fetch(
        `${API_BASE_URL}/LOA/upload_file?id=${recordId}`,
        {
          method: "POST",
          credentials: "include",
          body: formDataToSend,
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to upload image: ${errorText}`);
      }

      const result = await response.json();
      return result;
    } catch (err) {
      toast.error("Error uploading image: " + err.message);
      return null;
    }
  };

  const handleDownload = async () => {
    setIsLoading(true);
    setError(null);

    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    const isCreator = await checkCreatorStatus();
    if (!isCreator) {
      toast.error("You are not authorized as a creator.");
      setIsLoading(false);
      return;
    }

    const recordId = await createTextRecord();
    if (!recordId) {
      setIsLoading(false);
      return;
    }

    const file = formData.image;
    const uploadResult = await uploadImage(file, recordId);
    if (!uploadResult) {
      setIsLoading(false);
      return;
    }

    // const jsonData = JSON.stringify(formData, null, 2);
    // const blob = new Blob([jsonData], { type: "application/json" });
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(blob);
    // link.download = "certificate.json";
    // link.click();

    toast.success("Certificate verified and downloaded successfully!");
    setIsLoading(false);
  };

  const inputRef = useRef(null);

  const handleEditClick = (fieldName) => {
    setEditingField(fieldName);
    setTimeout(() => inputRef.current.focus(), 0);
  };

  const handleBlur = () => {
    setEditingField(null);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setEditingField(null);
      handleAddCategory();
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="certificate-tool">
        <div className="upload-section">
          <div className="upload-box">
            {formData.image ? (
              <img
                src={URL.createObjectURL(formData.image)}
                alt="Uploaded artwork"
                className="uploaded-image"
              />
            ) : (
              <>
                Upload Content
                <br />
                <span>File size should be no more than 32 MB</span>
              </>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="upload-input"
            />
          </div>
          <div className="price-section">
            <div className="group">
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
              />
              <select
                name="currency"
                value={formData.currency}
                onChange={handleChange}
              >
                <option value="USD">USD</option>
                <option value="ETH">ETH</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form-section">
          <div className="label-group">
            <label className="form-label">Artwork Name</label>
            <span className="editable-wrapper">
              {editingField === "artworkName" ? (
                <input
                  type="text"
                  ref={inputRef}
                  name="artworkName"
                  value={formData.artworkName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                  className="editable-input"
                />
              ) : (
                <span
                  className="editable-text"
                  onClick={() => handleEditClick("artworkName")}
                >
                  {formData.artworkName || "Artwork Name"}{" "}
                  <span className="edit-icon">
                    <FaPen />
                  </span>
                </span>
              )}
            </span>
          </div>

          <div className="label-group">
            <label className="form-label">Artist Name</label>
            <span className="editable-wrapper">
              {editingField === "artistName" ? (
                <input
                  type="text"
                  ref={inputRef}
                  name="artistName"
                  value={formData.artistName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                  className="editable-input"
                />
              ) : (
                <span
                  className="editable-text"
                  onClick={() => handleEditClick("artistName")}
                >
                  {formData.artistName || "Artist Name"}{" "}
                  <span className="edit-icon">
                    <FaPen />
                  </span>
                </span>
              )}
            </span>
          </div>

          <div className="label-group">
            <label className="form-label">Collection</label>
            <span className="editable-wrapper">
              {editingField === "collection" ? (
                <input
                  type="text"
                  ref={inputRef}
                  name="collection"
                  value={formData.collection}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                  className="editable-input"
                />
              ) : (
                <span
                  className="editable-text"
                  onClick={() => handleEditClick("collection")}
                >
                  {formData.collection || "Collection"}{" "}
                  <span className="edit-icon">
                    <FaPen />
                  </span>
                </span>
              )}
            </span>
          </div>

          <div className="label-group">
            <label className="form-label">Rarity</label>
            <div className="rarity-selection">
              <select
                name="rarity"
                value={formData.rarity}
                onChange={handleRarityChange}
                className="rarity-dropdown"
              >
                <option value="1/1">1/1</option>
                <option value="Open Edition">Open Edition</option>
                <option value="n/n">n/n</option>
              </select>
              {(formData.rarity === "Open Edition" ||
                formData.rarity === "n/n") && (
                <input
                  type="number"
                  name="tokensToMint"
                  value={formData.tokensToMint}
                  onChange={handleChange}
                  placeholder="Tokens to mint"
                  className="tokens-input"
                  min="1"
                />
              )}
            </div>
          </div>

          <div>
            <label htmlFor="">Type</label>
            <div className="type-selection">
              {["Digital art", "Physical art", "Sculpture", "Photography"].map(
                (type) => (
                  <button
                    key={type}
                    className={formData.type === type ? "selected" : ""}
                    onClick={() => setFormData({ ...formData, type })}
                  >
                    {type}
                  </button>
                )
              )}
            </div>
          </div>

          <div className="label-group">
            <label className="form-label">Medium</label>
            <span className="editable-wrapper">
              {editingField === "medium" ? (
                <input
                  type="text"
                  ref={inputRef}
                  name="medium"
                  value={formData.medium}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                  className="editable-input"
                />
              ) : (
                <span
                  className="editable-text"
                  onClick={() => handleEditClick("medium")}
                >
                  {formData.medium || "Medium"}{" "}
                  <span className="edit-icon">
                    <FaPen />
                  </span>
                </span>
              )}
            </span>
          </div>

          <div className="label-group">
            <label className="form-label">Categories</label>
            <div className="categories-list">
              {formData.categories.map((category) => (
                <div key={category} className="category-item">
                  {category}
                  <button
                    className="remove-category"
                    onClick={() => handleRemoveCategory(category)}
                  >
                    ✕
                  </button>
                </div>
              ))}
              <div className="category-input">
                {showCategoryInput ? (
                  <input
                    type="text"
                    value={categoryInput}
                    onChange={(e) => setCategoryInput(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Add category"
                    autoFocus
                  />
                ) : (
                  <button onClick={() => setShowCategoryInput(true)}>➕</button>
                )}
              </div>
            </div>
          </div>

          <label className="form-label">Description</label>
          <textarea
            name="description"
            className="textarea"
            value={formData.description}
            onChange={handleChange}
            required
          ></textarea>

          <button
            className="verify-button"
            onClick={handleDownload}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "VERIFY"}
          </button>
        </div>
      </div>
    </>
  );
};

export default CertificateTool;
